export const cronToFrequency = (cron?: string | null) => {
  if (cron?.includes('*')) {
    return 'daily'
  }
}

export const cronToDayOfWeek = (cron?: string | null) => {
  if (cron?.includes('*')) {
    return 'daily'
  }
}

export const getCronExpression = (freq?: string, day?: string) => {
  const dayMap: Record<string, number> = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
  }

  switch (freq) {
    case 'weekly':
      return `0 12 * * ${dayMap[day!]}` // Noon on specified day
    case 'bimonthly':
      return `0 12 15,25 * *` // Noon on 15th and 25th of month
    case 'monthly':
      return `0 12 25 * *` // Noon on 25th of month
    case 'daily':
      return '0 12 * * 1-5' // Noon every weekday
    default:
      return `0 12 * * ${dayMap[day!]}` // Default to weekly
  }
}
