export const Column = (
  props: React.HTMLAttributes<HTMLDivElement> & {
    c?: boolean
  }
) => (
  <div {...props} className={`${props.className} ${props.c ? 'col-c' : 'col'}`}>
    {props.children}
  </div>
)

export const Row = (
  props: React.HTMLAttributes<HTMLDivElement> & {
    c?: boolean
  }
) => (
  <div {...props} className={`${props.className} ${props.c ? 'row-c' : 'row'}`}>
    {props.children}
  </div>
)
