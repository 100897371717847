import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { UserActivity_ } from '../../apiUserActivity'
import { months } from './consts'

dayjs.extend(relativeTime)

export const getToolStats = (user_activities?: UserActivity_[]) => {
  const monthlyUserCount = months.map((monthObj) => {
    const userActivitiesThisMonth = user_activities?.filter((activity) => {
      const activityMonth = dayjs(activity.last_visited).month() + 1 // Adding 1 because dayjs months are 0-indexed
      return activityMonth === monthObj.month
    })

    const usersThisMonth = userActivitiesThisMonth
      ?.flat()
      .map((activity) => activity.org_user_id)

    return {
      label: monthObj.label,
      value: new Set(usersThisMonth).size,
    }
  })

  const monthlyUserVisits = months
    .map((monthObj) => {
      const userActivitiesThisMonth = user_activities?.filter((activity) => {
        const activityMonth = dayjs(activity.last_visited).month() + 1
        return activityMonth === monthObj.month
      })
      return userActivitiesThisMonth?.length || 0
    })
    .reduce((total, visits) => total + visits, 0)

  return {
    userCount: new Set(user_activities?.map((user) => user.org_user_id)).size,
    monthlyUserCount,
    monthlyUserVisits,
  }
}
