import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ExternalLink, ThumbsUp, Trash, TriangleAlert } from 'lucide-react'
import { useState } from 'react'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { useVendorsApi } from 'src/api/hooks/apiVendors'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

dayjs.extend(relativeTime)

function dedupeArray(array?: any[]) {
  const seen = new Set()

  return array?.filter((item) => {
    const uniqueId = item?.vendor.id // Using tool.id for deduplication
    if (seen.has(uniqueId)) {
      return false // Skip if already seen
    }

    seen.add(uniqueId)
    return true // Include if not seen
  })
}

export const UntrackedTableAdmin = () => {
  const { toolsAll } = useToolsApi({})
  const { deleteVendor, updateVendor } = useVendorsApi({})
  const [filterStatus, setFilterStatus] = useState<string>('not_in_stack')
  const [filterText, setFilterText] = useState('')

  const tools = dedupeArray(toolsAll?.tools || [])

  const filteredTools = tools?.filter((tool) => {
    // First check if text filter matches
    const textMatches =
      filterText === '' ||
      tool.vendor.name?.toLowerCase().includes(filterText.toLowerCase()) ||
      tool.vendor.root_domain?.toLowerCase().includes(filterText.toLowerCase())

    // Then check if status filter matches
    const statusMatches =
      filterStatus === 'blocked'
        ? tool.vendor.status === 'blocked' // For blocked status, check vendor.status
        : tool.status === filterStatus && tool.vendor.status !== 'blocked' // For other statuses, ensure vendor is not blocked

    // Tool must match both filters
    return textMatches && statusMatches
  })

  return (
    <>
      <Row className="mb-4 gap-2 items-center justify-between">
        <Row className="gap-2">
          <Input
            placeholder="Filter tools..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />

          <Select onValueChange={setFilterStatus} defaultValue="not_in_stack">
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="not_in_stack">
                Not tracked (
                {
                  toolsAll?.tools?.filter(
                    (tool) => tool.status === 'not_in_stack'
                  )?.length
                }
                )
              </SelectItem>

              <SelectItem value="ignored">
                Ignored (
                {
                  toolsAll?.tools?.filter((tool) => tool.status === 'ignored')
                    ?.length
                }
                )
              </SelectItem>

              <SelectItem value="in_stack">
                In stack (
                {
                  toolsAll?.tools?.filter((tool) => tool.status === 'in_stack')
                    ?.length
                }
                )
              </SelectItem>

              <SelectItem value="blocked">
                Blocked (
                {
                  toolsAll?.tools?.filter(
                    (tool) => tool.vendor.status === 'blocked'
                  )?.length
                }
                )
              </SelectItem>
            </SelectContent>
          </Select>
        </Row>
      </Row>

      <div className="overflow-auto h-[calc(100vh-280px)]">
        <Table>
          <TableBody>
            {filteredTools
              ?.sort(
                (a, b) =>
                  new Date(b.vendor.created_at).getTime() -
                  new Date(a.vendor.created_at).getTime()
              )
              .map((tool) => {
                return (
                  <TableRow key={tool.id} className="bg-gray-50/80">
                    <TableCell>
                      <Row className="flex items-center gap-3">
                        <SaasIcon
                          toolName={tool?.vendor.name}
                          src={tool?.vendor.logo_url || ''}
                          size="lg"
                        />
                        <Column>
                          <h2
                            className="text-sm font-medium cursor-pointer"
                            onClick={() =>
                              window.open(tool?.vendor.url ?? '', '_blank')
                            }
                          >
                            <Row>
                              {tool?.vendor.name}
                              <ExternalLink className="w-4 h-4 ml-2" />
                            </Row>
                          </h2>

                          <span className="text-xs font-normal">
                            {tool.vendor.root_domain}
                          </span>
                        </Column>
                      </Row>
                    </TableCell>

                    <TableCell>
                      <span className="text-xs font-normal">
                        {tool.vendor.description}
                      </span>
                    </TableCell>

                    <TableCell>
                      <Column className="w-[100px]">
                        <span className="text-xs font-normal">
                          {dayjs(tool.vendor.created_at).fromNow()}
                        </span>

                        <span className="text-xs font-normal">
                          {dayjs(tool.vendor.created_at).format('MMM D, YYYY')}
                        </span>
                      </Column>
                    </TableCell>

                    <TableCell className="text-right w-[200px]">
                      <Row className="gap-2">
                        {tool.vendor.status !== 'blocked' ? (
                          <Button
                            size="sm"
                            variant="white"
                            onClick={async () => {
                              await updateVendor.mutateAsync({
                                id: tool.vendor.id,
                                status: 'blocked',
                              })
                            }}
                          >
                            Block
                            <TriangleAlert className="w-4 h-4 ml-2 text-orange-500" />
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="white"
                            onClick={async () => {
                              await updateVendor.mutateAsync({
                                id: tool.vendor.id,
                                status: null,
                              })
                            }}
                          >
                            Unblock
                            <ThumbsUp className="w-4 h-4 ml-2 text-green-500" />
                          </Button>
                        )}

                        <Button
                          size="sm"
                          variant="white"
                          onClick={async () => {
                            await deleteVendor.mutateAsync(tool.vendor.id!)
                          }}
                        >
                          Delete vendor
                          <Trash className="w-4 h-4 ml-2 text-red-500" />
                        </Button>
                      </Row>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
