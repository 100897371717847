import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'
import { cn } from 'src/shared/components/ui/utils'

export const StatCard = ({
  title,
  value,
  icon,
  description,
  color,
  onClick,
}: {
  title: string
  value?: string | number
  icon: React.ReactNode
  description: string
  color?: 'green' | 'red' | null
  onClick?: () => void
}) => (
  <Card
    className={cn(
      'flex justify-between items-center gap-4 w-full',
      onClick && 'cursor-pointer'
    )}
    onClick={onClick}
  >
    <Column className="">
      <p className="text-sm font-semibold text-muted-foreground">{title}</p>
      <h1
        className={cn(
          'text-2xl font-medium',
          color === 'green' && 'text-green-600',
          color === 'red' && 'text-red-600'
        )}
      >
        {value}
      </h1>
      <p className="text-sm font-normal text-muted-foreground">{description}</p>
    </Column>

    {icon}
  </Card>
)
