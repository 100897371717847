import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ArrowLeft, CirclePlus, Crown } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useOrgsApi } from 'src/api/hooks/apiOrgs'
import { useUsersApi } from 'src/api/hooks/apiUsers'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Input,
  ScrollArea,
} from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { Roles, getSubscriptionName } from 'src/shared/consts'
import { useAuth } from 'src/shared/hooks/authProvider'

dayjs.extend(relativeTime)

export const OrgDialog = () => {
  const [open, setOpen] = useState(false)
  const [isCreatingOrg, setIsCreatingOrg] = useState(false)
  const [orgName, setOrgName] = useState('')
  const { createOrgUser } = useOrgUsersApi()
  const { updateUser } = useUsersApi()
  const { orgId } = useOrgIdApi()
  const { createOrganization, organizations, organization } = useOrgsApi({
    orgId,
  })
  const { session } = useAuth()
  const queryClient = useQueryClient()

  const handleCreateOrg = async () => {
    const res = await createOrganization.mutateAsync({ name: orgName })
    const orgId = res.data?.[0].id

    await createOrgUser.mutateAsync({
      organization_id: orgId!,
      user_id: session?.user?.id!,
      role_id: Roles.ADMIN,
    })

    await queryClient.invalidateQueries({ queryKey: [queryKeys.organizations] })

    setIsCreatingOrg(false)
    setOrgName('')
  }

  const CreateOrg = () => {
    return (
      <Column className="gap-2">
        <Row className="items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsCreatingOrg(false)}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-lg font-semibold">New organization</h2>
        </Row>

        <Card>
          <Column className="gap-4">
            <Input
              label="Org. name"
              id="orgName"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter organization name"
            />

            <Column>
              <p className="text-sm">Select a plan</p>

              <Select defaultValue="2">
                <SelectTrigger>
                  <SelectValue placeholder="Select plan" />
                </SelectTrigger>

                <SelectContent>
                  <SelectItem key="1" value="1">
                    Starter - $29/month
                  </SelectItem>
                  <SelectItem key="2" value="2">
                    Medium - $59/month
                  </SelectItem>
                  <SelectItem key="3" value="3">
                    Large - $79/month
                  </SelectItem>
                </SelectContent>
              </Select>
            </Column>

            <Row className="justify-end mt-4 items-center">
              <p className="text-sm text-red-500 mr-4">
                Contact us to add another organization.
              </p>

              <Button onClick={handleCreateOrg} disabled>
                Create
              </Button>
            </Row>
          </Column>
        </Card>
      </Column>
    )
  }

  const Organizations = () => {
    return (
      <ScrollArea className="h-[250px] mt-2">
        <div className="grid grid-cols-2 gap-4">
          {organizations?.data?.map((org) => (
            <Card
              className="h-[150px] cursor-pointer hover:bg-gray-5"
              onClick={async () => {
                // @ts-ignore
                await updateUser.mutateAsync({
                  id: session?.user?.id!,
                  current_org_id: org.organization?.id!,
                })
                window.location.reload()
              }}
            >
              <Column className="h-full">
                <p className="text-sm font-medium">{org.organization?.name}</p>
                <p className="text-sm text-neutral-500">
                  Created{' '}
                  {dayjs(org.organization?.created_at).format('MMM DD, YYYY')}
                </p>

                <Badge
                  variant="secondary"
                  className="py-2 px-3 rounded-full mt-auto w-fit"
                >
                  <Crown className="h-4 w-4 text-yellow-500 mr-1" />
                  {getSubscriptionName(org.organization?.stripe_product_id)}
                </Badge>
              </Column>
            </Card>
          ))}
        </div>
      </ScrollArea>
    )
  }

  return (
    <>
      <Row
        className="items-center justify-between gap-2 py-2 px-2 bg-slate-100 rounded-full cursor-pointer text-neutral-600"
        onClick={() => setOpen(true)}
      >
        <span className="text-sm font-medium ml-1">
          {organization?.data?.name}
        </span>

        <Badge className="bg-white text-black py-1 px-2 rounded-full">
          <Crown className="h-4 w-4 text-yellow-500 mr-1" />
          {getSubscriptionName(organization?.data?.stripe_product_id)}
        </Badge>
      </Row>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="h-[361px]">
          {isCreatingOrg ? (
            CreateOrg()
          ) : (
            <Column className="gap-2">
              <Row className="items-center gap-2">
                <h2 className="text-lg font-semibold">Organizations</h2>
                <Button
                  size="sm"
                  variant="outline"
                  className="w-fit bg-white"
                  onClick={() => setIsCreatingOrg(true)}
                >
                  <CirclePlus className="mr-2 h-4 w-4" />
                  Add
                </Button>
              </Row>

              <Organizations />
            </Column>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
