import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LogRocket from 'logrocket'
import posthog from 'posthog-js'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App, queryClient } from './App'
import { SupabaseProvider } from './api/supabase'
import './global.css'
import reportWebVitals from './reportWebVitals'
import { Toaster } from './shared/components/ui/toaster'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

LogRocket.init('pinnone/pinnone')

posthog.init('phc_MlA4O0eW3s0GOr018hFLXUIN354RBuKneOEE7VHKiPS', {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'always',
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SupabaseProvider>
        <Toaster />
        <App />
      </SupabaseProvider>

      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
