export const months = [
  { label: 'Jan', value: 0, month: 1 },
  { label: 'Feb', value: 0, month: 2 },
  { label: 'Mar', value: 0, month: 3 },
  { label: 'Apr', value: 0, month: 4 },
  { label: 'May', value: 0, month: 5 },
  { label: 'Jun', value: 0, month: 6 },
  { label: 'Jul', value: 0, month: 7 },
  { label: 'Aug', value: 0, month: 8 },
  { label: 'Sep', value: 0, month: 9 },
  { label: 'Oct', value: 0, month: 10 },
  { label: 'Nov', value: 0, month: 11 },
  { label: 'Dec', value: 0, month: 12 },
]

export const divisorMap = {
  YEARLY: 12,
  QUARTERLY: 3,
  MONTHLY: 1,
}
