import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ExternalLink, Mail, SendHorizonal } from 'lucide-react'
import { useState } from 'react'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useOrgsApi } from 'src/api/hooks/apiOrgs'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { useAskTeam } from 'src/api/server/useAskTeam'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Tooltip } from 'src/shared/components/ui'
import { Alert } from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/shared/components/ui/dialog'
import { Input } from 'src/shared/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import { Switch } from 'src/shared/components/ui/switch'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'
import { Textarea } from 'src/shared/components/ui/textarea'

import { cronToDayOfWeek, getCronExpression } from './utils'
import { cronToFrequency } from './utils'

dayjs.extend(relativeTime)

export const UntrackedTable = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const { updateTool } = useToolsApi({ organization_id: orgId || '' })
  const [filterStatus, setFilterStatus] = useState<string>('not_in_stack')
  const [filterText, setFilterText] = useState('')
  const { orgUser } = useOrgUsersApi()
  const { organization, updateOrganization } = useOrgsApi({
    orgId: orgId || '',
  })
  const [isAskTeamOpen, setIsAskTeamOpen] = useState(false)
  const [isAutoAuditOpen, setIsAutoAuditOpen] = useState(false)
  const askTeam = useAskTeam()
  const [askTeamMessage, setAskTeamMessage] = useState(
    'Hey! Could you help out with identifying which tools we are using, and also maybe the cost if you know that?'
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAutoAuditEnabled, setIsAutoAuditEnabled] = useState(false)
  const [frequency, setFrequency] = useState(
    cronToFrequency(organization?.data?.auto_audit_cron)
  )
  const [dayOfWeek, setDayOfWeek] = useState(
    cronToDayOfWeek(organization?.data?.auto_audit_cron)
  )

  const filteredTools = tools?.tools?.filter(
    (tool) =>
      tool.status === filterStatus &&
      (filterText === '' ||
        tool.vendor.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        tool.vendor.root_domain
          ?.toLowerCase()
          .includes(filterText.toLowerCase()))
  )

  return (
    <>
      <Row className="mb-4 gap-2 items-center justify-between">
        <Row className="gap-2">
          <Input
            placeholder="Filter tools..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />

          <Select onValueChange={setFilterStatus} defaultValue="not_in_stack">
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>

            <SelectContent>
              <SelectItem value="not_in_stack">
                Not tracked (
                {
                  tools?.tools?.filter((tool) => tool.status === 'not_in_stack')
                    ?.length
                }
                )
              </SelectItem>

              <SelectItem value="ignored">
                Ignored (
                {
                  tools?.tools?.filter((tool) => tool.status === 'ignored')
                    ?.length
                }
                )
              </SelectItem>
            </SelectContent>
          </Select>

          <Tooltip
            side="right"
            content={
              <Column className="gap-2 w-[450px]">
                <p className="text-sm">
                  Remember, the extension is scanning the{' '}
                  <span className="font-bold">
                    last hour of activity, every 10 minutes.
                  </span>
                  <br />
                  <br />
                  If the tool does not show up, click "Add tools manually" in
                  the top right corner.
                </p>
              </Column>
            }
            delayDuration={0}
          >
            <Button variant="ghost" className="text-xs">
              Not finding your tool?
            </Button>
          </Tooltip>
        </Row>

        <Row className="gap-2">
          <Dialog open={isAutoAuditOpen} onOpenChange={setIsAutoAuditOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" className="text-xs">
                Auto audit
                <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse ml-2" />
              </Button>
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>Auto audit</DialogTitle>
              </DialogHeader>

              <div className="grid gap-4 py-4 ">
                <div className="flex flex-row items-center justify-between rounded-lg border p-4 bg-white">
                  <Row className="justify-between items-center w-full">
                    <Column className="gap-2">
                      <p className="font-medium">Toggle auto audit</p>
                      <p className="text-sm text-gray-500">
                        Automatically emails your team to identify which tools
                        they are using, and the costs.
                      </p>
                    </Column>

                    <Switch
                      checked //={isAutoAuditEnabled}
                      onCheckedChange={setIsAutoAuditEnabled}
                    />
                  </Row>
                </div>

                <div className="flex flex-row items-center justify-between rounded-lg border p-4 bg-white">
                  <Row className="justify-between items-center w-full">
                    <Column className="gap-2">
                      <p className="font-medium">Schedule</p>
                      <p className="text-sm text-gray-500">
                        How often should we email and ask your team?
                      </p>
                    </Column>

                    <Row className="gap-2">
                      <Select onValueChange={setFrequency}>
                        <SelectTrigger>
                          <SelectValue placeholder="Weekly" />
                        </SelectTrigger>

                        <SelectContent>
                          <SelectItem value="weekly">Weekly</SelectItem>
                          <SelectItem value="bimonthly">Bi-Monthly</SelectItem>
                          <SelectItem value="monthly">Monthly</SelectItem>
                        </SelectContent>
                      </Select>

                      {frequency === 'weekly' && (
                        <Select onValueChange={setDayOfWeek}>
                          <SelectTrigger>
                            <SelectValue placeholder="Fridays" />
                          </SelectTrigger>

                          <SelectContent>
                            <SelectItem value="monday">Mondays</SelectItem>
                            <SelectItem value="tuesday">Tuesdays</SelectItem>
                            <SelectItem value="wednesday">
                              Wednesdays
                            </SelectItem>
                            <SelectItem value="thursday">Thursdays</SelectItem>
                            <SelectItem value="friday">Fridays</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    </Row>
                  </Row>
                </div>

                <p className="text-sm text-gray-500 ml-2">
                  {/* {frequency === 'weekly' &&
                    `Your emails will be sent 12:00 every ${
                      // dayOfWeek?.charAt(0).toUpperCase() + dayOfWeek?.slice(1)
                    }`} */}
                  {frequency === 'bimonthly' &&
                    'Your emails will be sent 12:00 on the 15th and 25th of each month'}
                  {frequency === 'monthly' &&
                    'Your emails will be sent 12:00 on the 25th of each month'}
                  {frequency === 'daily' &&
                    'Your emails will be sent 12:00 every weekday'}
                </p>
              </div>

              <DialogFooter>
                <Button
                  isLoading={updateOrganization.isLoading}
                  onClick={() => {
                    const cronExpression = getCronExpression(
                      frequency,
                      dayOfWeek
                    )

                    updateOrganization
                      .mutateAsync({
                        id: orgId || '',
                        auto_audit_cron: cronExpression,
                      })
                      .then(() => {
                        setIsAutoAuditOpen(false)
                      })
                  }}
                >
                  Save
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={isAskTeamOpen} onOpenChange={setIsAskTeamOpen}>
            <DialogTrigger asChild>
              <Button>
                Ask team
                <Mail className="w-4 h-4 ml-2" />
              </Button>
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>Ask your team about tools</DialogTitle>
                <DialogDescription>
                  If a person is using a tool, they'll get an email asking them
                  if it should be tracked.
                </DialogDescription>
              </DialogHeader>

              <div className="grid gap-4 py-4">
                <Textarea
                  value={askTeamMessage}
                  onChange={(e) => setAskTeamMessage(e.target.value)}
                  placeholder="Add a message (optional)"
                />
              </div>

              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsAskTeamOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  isLoading={askTeam.isLoading}
                  onClick={async () =>
                    await askTeam
                      .mutateAsync({
                        message: askTeamMessage,
                        organization_id: orgId || '',
                      })
                      .then(() => {
                        setIsAskTeamOpen(false)
                        setAskTeamMessage('')
                      })
                  }
                >
                  Send requests
                  <SendHorizonal className="w-4 h-4 ml-2" />
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </Row>
      </Row>

      <div className="overflow-auto h-[calc(100vh-280px)]">
        <Table>
          <TableBody>
            {!filteredTools?.length && (
              <TableRow>
                <TableCell className="h-24">
                  <Alert className="w-fit mx-auto my-4">
                    <span className="font-bold">Invite team members</span> to
                    start identifying their tools, or wait for the data to be
                    collected
                  </Alert>
                </TableCell>
              </TableRow>
            )}

            {filteredTools
              ?.sort(
                (a, b) =>
                  new Date(b.vendor.created_at).getTime() -
                  new Date(a.vendor.created_at).getTime()
              )
              .map((tool) => {
                return (
                  <TableRow key={tool.id} className="bg-gray-50/80">
                    <TableCell className="py-4">
                      <Row className="flex items-center gap-3">
                        <SaasIcon
                          toolName={tool?.vendor.name}
                          src={tool?.vendor.logo_url || ''}
                          size="lg"
                        />
                        <Column>
                          <h2
                            className="text-sm font-medium cursor-pointer"
                            onClick={() =>
                              window.open(tool?.vendor.url ?? '', '_blank')
                            }
                          >
                            <Row>
                              {tool?.vendor.name}
                              <ExternalLink className="w-4 h-4 ml-2" />
                            </Row>
                          </h2>

                          <span className="text-xs font-normal">
                            {tool.vendor.root_domain}
                          </span>
                        </Column>
                      </Row>
                    </TableCell>

                    <TableCell className="w-[230px]">
                      <Tooltip
                        delayDuration={100}
                        side="right"
                        content={
                          <p className="text-xs font-normal max-w-[300px]">
                            {tool.vendor.description}
                          </p>
                        }
                      >
                        <p className="text-xs font-normal">
                          {tool.vendor.description?.slice(0, 87) +
                            (tool.vendor.description?.length > 87 && '...')}
                        </p>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Column>
                        <span className="text-xs font-normal">
                          {dayjs(tool.vendor.created_at).fromNow()}
                        </span>

                        <span className="text-xs font-normal">
                          ({dayjs(tool.vendor.created_at).format('MMM D, YYYY')}
                          )
                        </span>
                      </Column>
                    </TableCell>

                    <TableCell className="w-[200px]">
                      {tool.status_should_be === 'not_set' && (
                        <Badge
                          variant="outline"
                          className="text-gray-500 border-gray-500 bg-gray-50/80 rounded-full"
                        >
                          Waiting for response
                        </Badge>
                      )}

                      {tool.status_should_be === 'untracked' && (
                        <Badge
                          variant="outline"
                          className="text-red-500 border-red-500 bg-red-50/80 rounded-full"
                        >
                          Should not be tracked
                        </Badge>
                      )}

                      {tool.status_should_be === 'tracked' && (
                        <Badge
                          variant="outline"
                          className="text-green-500 border-green-500 bg-green-50/80 rounded-full"
                        >
                          Should be tracked
                        </Badge>
                      )}
                    </TableCell>

                    {tool.status === 'not_in_stack' && (
                      <TableCell className="text-right">
                        <>
                          <Button
                            className=""
                            variant="ghost"
                            onClick={() => {
                              updateTool.mutate({
                                id: tool.id!,
                                status: 'ignored',
                              })
                            }}
                          >
                            Ignore
                          </Button>

                          <Button
                            variant="white"
                            onClick={async () => {
                              await updateTool.mutateAsync({
                                id: tool.id!,
                                vendor_id: tool?.vendor.id!,
                                organization_id: orgId || '',
                                department: tool?.vendor.category || '',
                                owner_org_user_id: orgUser?.data?.id,
                                is_tracking: true,
                                status: 'in_stack',
                              })
                            }}
                          >
                            Start tracking tool
                          </Button>
                        </>
                      </TableCell>
                    )}

                    {tool.status === 'ignored' && (
                      <TableCell className="text-right">
                        <>
                          <Button
                            className=""
                            variant="outline"
                            onClick={async () => {
                              await updateTool.mutateAsync({
                                id: tool.id!,
                                department: tool?.vendor.category || '',
                                owner_org_user_id: orgUser?.data?.id,
                                is_tracking: true,
                                status: 'in_stack',
                              })
                            }}
                          >
                            Start tracking tool
                          </Button>
                        </>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
