import { FormProvider, UseFormReturn } from 'react-hook-form'

export const FormWrapper = ({
  onSubmit,
  methods,
  children,
}: {
  onSubmit: (values: any) => void
  methods: UseFormReturn<any>
  children: React.ReactNode
}) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-3">
        {children}
      </form>
    </FormProvider>
  )
}
