import { UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/shared/components/ui/form'

import { Textarea } from '../ui/textarea'

export const FormTextarea = ({
  name,
  label,
  description,
  methods,
  placeholder,
  className = '',
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
  placeholder?: string
  className?: string
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea {...field} placeholder={placeholder} />
          </FormControl>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
