import { zodResolver } from '@hookform/resolvers/zod'
import { Banknote, CalendarIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { useSubscriptionsApi } from 'src/api/hooks/apiSubscriptions'
import {
  FormDatePicker,
  FormInput,
  FormSelect,
  FormWrapper,
} from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Sheet, SheetContent } from 'src/shared/components/ui/sheet'
import { z } from 'zod'

import { pricingModelOptions, renewalFrequencyOptions } from './consts'

const formSchema = z.object({
  renewal_frequency: z.string(),
  starts_at: z.date(),
  next_renewal_date: z.date(),
  pricing_model: z.string(),
  flat_fee_cost: z.number().optional().or(z.string()), // OR string? huh.
  usage_based_cost: z.number().optional().or(z.string()),
  price_per_seat: z.number().optional().or(z.string()),
  number_of_seats: z.number().optional().or(z.string()),
  other_cost: z.number().optional().or(z.string()),
})

export const AddSubscriptionSheet = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const location = useLocation()

  let { tool_id } = useParams()
  const hash = location.hash.replace('#', '')
  if (!tool_id) tool_id = hash

  const { addSubscription } = useSubscriptionsApi({
    tool_id,
  })

  const handleSubmit = () => {
    const values = methods.getValues()
    addSubscription.mutate({
      tool_id: Number(tool_id!),
      renewal_frequency: values.renewal_frequency,
      status: 'ACTIVE',
      starts_at: values.starts_at.toISOString(),
      next_renewal_date: values.next_renewal_date.toISOString(),
      pricing_model: values.pricing_model,
      flat_fee_cost: Number(values.flat_fee_cost),
      usage_based_cost: Number(values.usage_based_cost),
      price_per_seat: Number(values.price_per_seat),
      number_of_seats: Number(values.number_of_seats),
      other_cost: Number(values.other_cost),
      currency: 'USD',
    })
    setIsOpen(false)
  }

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      renewal_frequency: 'MONTHLY',
      starts_at: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      next_renewal_date: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
      pricing_model: 'FLAT_FEE',
      flat_fee_cost: undefined,
      usage_based_cost: undefined,
      other_cost: undefined,
      price_per_seat: undefined,
      number_of_seats: undefined,
    },
  })

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent className="sm:max-w-[600px]">
        <Column className="gap-2 mt-4">
          <FormWrapper onSubmit={handleSubmit} methods={methods}>
            <Column className="gap-2">
              <Card>
                <Row className="gap-2 items-center mb-2">
                  <CalendarIcon className="w-4 h-4" />

                  <p className="text-sm font-semibold">Renewals</p>
                </Row>

                <FormSelect
                  name="renewal_frequency"
                  label="Renewal frequency"
                  options={renewalFrequencyOptions}
                  methods={methods}
                  className="w-full"
                />

                <Row className="gap-3">
                  <FormDatePicker
                    name="starts_at"
                    label="Start date"
                    methods={methods}
                    className="w-full"
                  />

                  <FormDatePicker
                    name="next_renewal_date"
                    label="Next renewal date"
                    methods={methods}
                    className="w-full"
                  />
                </Row>
              </Card>

              <Card>
                <Row className="gap-2 items-center mb-2">
                  <Banknote className="w-4 h-4" />
                  <p className="text-sm font-semibold">Cost</p>
                </Row>

                <Row className="gap-3">
                  <FormSelect
                    name="pricing_model"
                    label="Pricing model"
                    options={pricingModelOptions}
                    methods={methods}
                    className="w-full"
                  />

                  {methods.watch('pricing_model') === 'FLAT_FEE' && (
                    <FormInput
                      name="flat_fee_cost"
                      label="Price ($)"
                      methods={methods}
                      className="w-full"
                      type="number"
                    />
                  )}
                </Row>

                {methods.watch('pricing_model') === 'PER_SEAT' && (
                  <Row className="gap-3">
                    <FormInput
                      name="number_of_seats"
                      label="Number of seats"
                      type="number"
                      methods={methods}
                      className="w-full"
                    />

                    <FormInput
                      name="price_per_seat"
                      label="Price per seat ($)"
                      methods={methods}
                      className="w-full"
                      type="number"
                    />
                  </Row>
                )}

                {methods.watch('pricing_model') === 'USAGE_BASED' && (
                  <FormInput
                    name="usage_based_cost"
                    label="Usage cost ($)"
                    type="number"
                    methods={methods}
                    className="w-full"
                  />
                )}

                {methods.watch('pricing_model') === 'OTHER' && (
                  <FormInput
                    name="other_cost"
                    label="Other cost ($)"
                    type="number"
                    methods={methods}
                    className="w-full"
                  />
                )}
              </Card>
            </Column>

            <Row className="gap-2">
              <Button type="submit">Save</Button>

              <Button
                variant="outline"
                onClick={() => {
                  setIsOpen(false)
                  methods.reset()
                }}
              >
                Cancel
              </Button>
            </Row>
          </FormWrapper>
        </Column>
      </SheetContent>
    </Sheet>
  )
}
