import { Session } from '@supabase/supabase-js'
import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSupabaseClient } from '../../api/supabase'
import { paths } from '../../routes/paths'

const AuthContext = createContext<{
  auth: SupabaseAuthClient | null
  session: Session | null | undefined
  loading: boolean
}>({ auth: null, session: null, loading: true })

export const AuthProvider = ({ children }: any) => {
  const [session, setSession] = useState<Session | null>()
  const [loading, setLoading] = useState(true)

  const navigation = useNavigate()
  const supabaseClient = useSupabaseClient()

  useEffect(() => {
    const setData = async () => {
      supabaseClient.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        setLoading(false)
      })
    }

    const { data: listener } = supabaseClient.auth.onAuthStateChange(
      (_event, session) => {
        if (_event === 'SIGNED_OUT') {
          navigation(paths.loginSignUp)
        }

        setSession(session)
        setLoading(false)
      }
    )

    setData()

    return () => {
      listener?.subscription.unsubscribe()
    }
  }, [])

  const value = { auth: supabaseClient.auth, session, loading }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
