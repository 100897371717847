import { ChartConfig } from 'src/shared/components/ui/chart'

export const chartConfig = {
  Product: {
    label: 'Product',
  },
  IT: {
    label: 'IT',
  },
  Sales: {
    label: 'Sales',
  },
  Marketing: {
    label: 'Marketing',
  },
  HR: {
    label: 'HR',
  },
  'Data & Analytics': {
    label: 'Data & Analytics',
  },
  Engineering: {
    label: 'Engineering',
  },
  Finance: {
    label: 'Finance',
  },
  'Customer Success': {
    label: 'Customer Success',
  },
  Legal: {
    label: 'Legal',
  },
  Other: {
    label: 'Other',
  },
} satisfies ChartConfig
