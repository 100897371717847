import { useQueryClient } from '@tanstack/react-query'
import { Blocks, Cctv, PlusCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useVendorsApi } from 'src/api/hooks/apiVendors'
import { useUntrackedTools } from 'src/api/hooks/helpers/useUntrackedTools'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { useAddToolsManually } from 'src/api/server/useAddToolsManually'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from 'src/shared/components/ui/dialog'
import {
  InputAutocomplete,
  SelectedItem,
} from 'src/shared/components/ui/input-autocomplete'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { useDebounce } from 'use-debounce'

import { Receipts } from './Receipts'
import { StackTable } from './StackTable'
import { UntrackedTable } from './UntrackedTable'
import { UntrackedTableAdmin } from './UntrackedTableAdmin'

export const StackPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([])
  const [searchValue, setSearchValue] = useState('')

  const location = useLocation()
  const navigate = useNavigate()
  const hash = location.hash.replace('#', '') || 'tracked'

  const queryClient = useQueryClient()
  const addToolsManually = useAddToolsManually()
  const { orgId } = useOrgIdApi()
  const [searchText] = useDebounce(searchValue, 250)
  const { orgUser } = useOrgUsersApi()

  const { vendorsSearch } = useVendorsApi({
    orgId: orgId || '',
    search: searchText,
  })
  const untrackedTools = useUntrackedTools(orgId || '')

  const options = searchValue
    ? vendorsSearch?.data?.map((vendor) => ({
        id: vendor.id,
        value: vendor.root_domain || '',
      })) || []
    : []

  useEffect(() => {
    if (!location.hash) {
      navigate('#tracked', { replace: true })
    }
  }, [location.hash, navigate])

  return (
    <Column className="gap-4 w-full h-full">
      <Row className="gap-2 items-center mb-1">
        <Blocks />
        <h1 className="text-2xl font-medium">Tools</h1>

        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogTrigger asChild>
            <Button className="ml-auto bg-white" variant="outline">
              <PlusCircle className="mr-2 h-4 w-4" />
              Add tools manually
            </Button>
          </DialogTrigger>

          <DialogContent className="max-w-lg min-h-[300px]">
            <Column className="gap-2">
              <p className="text-lg font-medium">Manually add tools</p>

              <p className="text-sm text-muted-foreground">
                Use if the chrome extension did not find your tool, or if the
                tool is outside of chrome and it can't be detected.
              </p>
            </Column>

            <Row className="gap-4 mt-4 items-end">
              <InputAutocomplete
                options={options}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />

              <Button
                className="ml-auto"
                isLoading={addToolsManually.isLoading}
                disabled={selectedItems.length === 0}
                onClick={async () => {
                  await addToolsManually
                    .mutateAsync({
                      owner_org_user_id: orgUser?.data?.id!,
                      vendors: selectedItems.map((item) => item.value),
                      organization_id: orgId,
                    })
                    .then(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.tools],
                      })
                    })
                  setIsModalOpen(false)
                  setSelectedItems([])
                }}
              >
                <PlusCircle className="mr-2 h-4 w-4" />
                Add {selectedItems.length || ''} tools
              </Button>
            </Row>
          </DialogContent>
        </Dialog>
      </Row>

      <Tabs value={hash} onValueChange={(value) => navigate('#' + value)}>
        <TabsList className="w-fit">
          <TabsTrigger value="tracked">
            <Cctv className="mr-2 h-4 w-4" />
            Tracked
          </TabsTrigger>

          <TabsTrigger value="untracked">
            <Row className="gap-2 items-center">
              Untracked
              <div className="bg-orange-500 text-white rounded-full px-2 py-0.5 text-xs">
                {untrackedTools}
              </div>
            </Row>
          </TabsTrigger>

          {orgId === '3358b7fd-4b6a-49ac-a3e0-f10585d37adb' && (
            <TabsTrigger value="admin">Admin</TabsTrigger>
          )}
        </TabsList>

        <TabsContent value="tracked">
          <StackTable />
        </TabsContent>

        <TabsContent value="untracked">
          <Card>
            <UntrackedTable />
          </Card>
        </TabsContent>

        {orgId === '3358b7fd-4b6a-49ac-a3e0-f10585d37adb' && (
          <TabsContent value="admin">
            <Card>
              <UntrackedTableAdmin />
            </Card>
          </TabsContent>
        )}

        <TabsContent value="receipts">
          <Card>
            <Receipts />
          </Card>
        </TabsContent>
      </Tabs>
    </Column>
  )
}
