import { Upload, X } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSupabaseClient } from 'src/api/supabase'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, ScrollArea } from 'src/shared/components/ui'

export const FileUpload = ({
  uploadedFiles,
  maxFiles,
  maxSize,
  onUploadComplete,
}: {
  uploadedFiles: string[]
  maxFiles: number
  maxSize: number
  onUploadComplete: (imageUrls: string) => void
}) => {
  const [files, setFiles] = useState<File[]>([])
  const supabase = useSupabaseClient()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
  })

  const removeFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
  }

  const uploadFiles = async () => {
    const uploadedUrls: string[] = []

    for (const file of files) {
      const urlSafeFileName = file.name.replace(/[^a-zA-Z0-9-_.]/g, '')
      const fileName = `${Date.now()}_${urlSafeFileName}`

      const { data, error } = await supabase.storage
        .from('attachments')
        .upload(fileName, file)

      if (error) {
        console.error('Error uploading file:', error)
      } else {
        console.log('File uploaded successfully:', data)
        const { data: publicUrlData } = supabase.storage
          .from('attachments')
          .getPublicUrl(fileName)

        if (publicUrlData) {
          uploadedUrls.push(publicUrlData.publicUrl)
        }
      }
    }

    setFiles([])

    const imageUrls = [...uploadedFiles, ...uploadedUrls].join(',')
    onUploadComplete(imageUrls)
  }

  return (
    <div className="w-full rounded-md">
      <Column className="gap-2 mb-3">
        {uploadedFiles?.map((file, index) => (
          <div className="relative" key={index}>
            <a
              href={file}
              target="_blank"
              download
              className="flex items-center p-2 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
              rel="noreferrer"
            >
              <span className="text-sm truncate w-[80%]">
                {file.split('/').pop()}
              </span>
            </a>

            <Button
              className="absolute top-1 right-2 h-6 w-6"
              variant="outline"
              size="icon"
              onClick={async () => {
                // Extract the file name from the URL
                const fileName = file.split('/').pop()
                if (fileName) {
                  // Remove the file from Supabase storage
                  const { error } = await supabase.storage
                    .from('attachments')
                    .remove([fileName])

                  if (error) {
                    console.error('Error deleting file from storage:', error)
                  } else {
                    console.log('File deleted successfully from storage')
                  }
                }

                // Update the file URLs list
                const newFileUrls = uploadedFiles
                  ?.filter((url) => url !== file)
                  .join(',')

                await onUploadComplete(newFileUrls)
              }}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
        ))}
      </Column>

      <div
        {...getRootProps()}
        className={`p-6 border border-dashed rounded-lg text-center cursor-pointer transition-colors bg-white ${
          isDragActive
            ? 'border-primary bg-primary/10'
            : 'border-gray-300 hover:border-primary'
        }`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-6 w-6 text-gray-400" />

        <p className="mt-2 text-sm text-gray-600">
          Drop files here or click to select
        </p>
      </div>

      {files.length > 0 && (
        <ScrollArea className="mt-4 h-[90px] bg-white p-2 rounded-md">
          <ul className="space-y-2">
            {files?.map((file, index) => (
              <li
                key={index + file.name}
                className="flex items-center p-2 bg-gray-100 rounded"
              >
                <span className="text-sm truncate w-[240px]">{file.name}</span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => removeFile(file)}
                  aria-label={`Remove ${file.name}`}
                >
                  <X className="h-4 w-4" />
                </Button>
              </li>
            ))}
          </ul>
        </ScrollArea>
      )}

      {files.length > 0 && (
        <Button className="mt-2" onClick={uploadFiles}>
          Upload {files.length} file{files.length === 1 ? '' : 's'}
        </Button>
      )}
    </div>
  )
}
