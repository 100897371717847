import {
  Blocks,
  CreditCard,
  LayoutDashboard,
  MousePointerClick,
  RefreshCcw,
  TriangleAlert,
  Wallet,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { useUntrackedTools } from 'src/api/hooks/helpers/useUntrackedTools'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'

import { Bars } from './Bars'
import MonthCalendar from './MonthCalendar'
import { StatCard } from './StatCard'

export const DashboardPage = () => {
  const navigate = useNavigate()

  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({
    organization_id: orgId || '',
  })
  const untrackedTools = useUntrackedTools(orgId || '')

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-1">
        <LayoutDashboard />
        <h1 className="text-2xl font-medium">Dashboard</h1>
      </Row>

      <Row className="gap-4">
        <StatCard
          title="New detected tools"
          value={untrackedTools}
          icon={
            <TriangleAlert
              className={`w-6 h-6 ${
                untrackedTools > 0 ? 'text-orange-500' : 'text-muted-foreground'
              }`}
            />
          }
          description="Click to review"
          onClick={() => navigate(`${paths.tools}#untracked`)}
        />

        <StatCard
          title="Annual spend"
          value={`$${Math.round(tools?.toolsStats.annualSpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Current run-rate"
        />

        <StatCard
          title="Monthly spend"
          value={`$${Math.round(tools?.toolsStats.monthlySpend || 0)}`}
          icon={<CreditCard className="w-6 h-6 text-muted-foreground" />}
          description="Current run-rate"
        />
      </Row>

      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <MonthCalendar />
        </div>

        <div className="col-span-2 gap-4">
          <Column className="gap-4">
            <Row className="gap-4">
              <StatCard
                title="Seat utilization"
                value={`$${
                  tools?.toolsStats.totalAnnualWasteSeatUtilization || 0
                }`}
                icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
                description="Potential savings"
                color={
                  tools?.toolsStats?.totalAnnualWasteSeatUtilization! > 0
                    ? 'green'
                    : null
                }
                onClick={() => navigate(paths.insights)}
              />

              <StatCard
                title="Low usage"
                value={`$${tools?.toolsStats.totalAnnualWasteLowUsage || 0}`}
                icon={<Wallet className="w-6 h-6 text-muted-foreground" />}
                description="Potential savings"
                color={
                  tools?.toolsStats?.totalAnnualWasteLowUsage! > 0
                    ? 'green'
                    : null
                }
                onClick={() => navigate(paths.insights)}
              />
            </Row>

            <Bars />
            <Row className="gap-4">
              <StatCard
                title="Tracked tools"
                value={`${tools?.tools?.filter(
                  (tool) => tool.status === 'in_stack'
                ).length}`}
                icon={<Blocks className="w-6 h-6 text-muted-foreground" />}
                description="Last 30 days"
              />
            </Row>

            <Row className="gap-4">
              <StatCard
                title="Active users"
                value={tools?.toolsStats.totalActiveUsers || 0}
                icon={
                  <MousePointerClick className="w-6 h-6 text-muted-foreground" />
                }
                description="Last 30 days"
              />

              <StatCard
                title="Subscriptions"
                value={`${tools?.toolsStats.totalActiveSubscriptions}`}
                icon={<RefreshCcw className="w-6 h-6 text-muted-foreground" />}
                description="Last 30 days"
              />
            </Row>
          </Column>
        </div>
      </div>
    </Column>
  )
}
