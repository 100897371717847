import { useEffect, useState } from 'react'

const gradientColors = {
  A: 'from-red-500 to-orange-500',
  B: 'from-blue-500 to-purple-500',
  C: 'from-green-500 to-teal-500',
  D: 'from-yellow-500 to-amber-500',
  E: 'from-indigo-500 to-blue-500',
  F: 'from-pink-500 to-rose-500',
  G: 'from-violet-500 to-purple-500',
  H: 'from-cyan-500 to-blue-500',
  I: 'from-emerald-500 to-green-500',
  J: 'from-orange-500 to-amber-500',
  K: 'from-red-500 to-orange-500',
  L: 'from-blue-500 to-purple-500',
  M: 'from-green-500 to-teal-500',
  N: 'from-yellow-500 to-amber-500',
  O: 'from-indigo-500 to-blue-500',
  P: 'from-pink-500 to-rose-500',
  Q: 'from-violet-500 to-purple-500',
  R: 'from-cyan-500 to-blue-500',
  S: 'from-emerald-500 to-green-500',
  T: 'from-orange-500 to-amber-500',
  U: 'from-red-500 to-orange-500',
  V: 'from-blue-500 to-purple-500',
  W: 'from-green-500 to-teal-500',
  X: 'from-yellow-500 to-amber-500',
  Y: 'from-indigo-500 to-blue-500',
  Z: 'from-pink-500 to-rose-500',
  '0': 'from-red-500 to-orange-500',
  '1': 'from-blue-500 to-purple-500',
  '2': 'from-green-500 to-teal-500',
  '3': 'from-yellow-500 to-amber-500',
  '4': 'from-indigo-500 to-blue-500',
  '5': 'from-pink-500 to-rose-500',
  '6': 'from-violet-500 to-purple-500',
  '7': 'from-cyan-500 to-blue-500',
  '8': 'from-emerald-500 to-green-500',
  '9': 'from-orange-500 to-amber-500',
}

const FallbackIcon = ({
  toolName,
  size,
}: {
  toolName?: string
  size: 'sm' | 'lg'
}) => {
  const firstTwoLetters = toolName?.slice(0, 2).toUpperCase() || 'TO'
  const gradient = gradientColors[firstTwoLetters[0]] || gradientColors['A']

  return (
    <div
      className={`bg-gradient-to-r ${gradient} rounded-md p-2 w-${
        size === 'sm' ? '5' : '10'
      } h-${
        size === 'sm' ? '5' : '10'
      } flex items-center justify-center opacity-80`}
    >
      <span
        className={`text-white font-bold ${
          size === 'sm' ? 'text-[10px]' : 'text-lg'
        }`}
      >
        {firstTwoLetters}
      </span>
    </div>
  )
}

export const SaasIcon = ({
  toolName,
  size = 'sm',
  src,
}: {
  toolName?: string
  size?: 'sm' | 'lg'
  src: string
}) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setImgLoaded(true)
    img.onerror = () => setImgLoaded(false)
  }, [src])

  if (src && imgLoaded) {
    return (
      <img
        src={src}
        alt={toolName}
        className={`rounded-md w-${size === 'sm' ? '5' : '10'} h-${
          size === 'sm' ? '5' : '10'
        } object-contain bg-slate-100`}
      />
    )
  }

  return <FallbackIcon toolName={toolName} size={size} />
}
