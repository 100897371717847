import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'src/shared/components/ui/dialog'
import { Switch } from 'src/shared/components/ui/switch'

dayjs.extend(relativeTime)

export const Settings = () => {
  const navigate = useNavigate()
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools, updateTool } = useToolsApi({
    organization_id: orgId || '',
  })

  const tool = tools?.tools?.find((t) => t.id === Number(tool_id))
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  return (
    <Column className="gap-4 w-full">
      <Card className="w-full justify-center items-center">
        <p className="text-lg font-semibold">Tool settings</p>

        <Column className="gap-4 w-full mt-5">
          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <Row className="justify-between items-center w-full">
              <Column className="gap-2">
                <p className="font-medium">Not used in Chrome</p>
                <p className="text-sm text-gray-500">
                  This tool is used outside of the chrome browser, and user
                  activity is not trackable.
                </p>
              </Column>

              <Switch
                checked={tool?.is_desktop_tool!}
                onCheckedChange={() => {
                  updateTool.mutate({
                    id: tool?.id!,
                    is_desktop_tool: !tool?.is_desktop_tool,
                  })
                }}
              />
            </Row>
          </div>

          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <Row className="justify-between items-center w-full">
              <Column className="gap-2">
                <p className="font-medium">Tracking</p>
                <p className="text-sm text-gray-500">
                  Track user activity for this tool.
                </p>
              </Column>

              <Switch
                checked={tool?.is_tracking}
                onCheckedChange={() => {
                  updateTool.mutate({
                    id: tool?.id!,
                    is_tracking: !tool?.is_tracking,
                  })
                }}
              />
            </Row>
          </div>

          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <Row className="justify-between items-center w-full">
              <Column className="gap-2">
                <p className="font-medium">Remove from stack</p>
                <p className="text-sm text-gray-500">
                  Remove this tool from the stack.
                </p>
              </Column>

              <Dialog
                open={isRemoveModalOpen}
                onOpenChange={setIsRemoveModalOpen}
              >
                <DialogContent>
                  <DialogHeader>
                    <h1 className="text-xl font-semibold">Remove tool</h1>
                  </DialogHeader>

                  <p className="text-sm font-normal text-muted-foreground">
                    Are you sure you want to remove this tool from your stack?
                    This action cannot be undone.
                  </p>

                  <Row className="justify-end gap-2 mt-4">
                    <Button
                      variant="outline"
                      onClick={() => setIsRemoveModalOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={async () => {
                        await updateTool
                          .mutateAsync({
                            id: tool?.id!,
                            status: 'not_in_stack',
                          })
                          .then(() => {
                            navigate(paths.tools)
                            setIsRemoveModalOpen(false)
                          })
                      }}
                    >
                      Remove
                    </Button>
                  </Row>
                </DialogContent>
              </Dialog>

              <Button
                variant="destructive"
                onClick={() => setIsRemoveModalOpen(true)}
              >
                Remove
              </Button>
            </Row>
          </div>
        </Column>
      </Card>
    </Column>
  )
}
