import { ArrowRight, CircleCheckIcon, CircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUsersApi } from 'src/api/hooks/apiUsers'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'

const getInitialState = () => {
  const saved = localStorage.getItem('onboardingState')

  return saved
    ? JSON.parse(saved)
    : {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
      }
}

export const OnboardingWidget = () => {
  const [onboardingState, setOnboardingState] = useState(getInitialState())

  const navigate = useNavigate()
  const { session } = useAuth()
  const { updateUser } = useUsersApi()

  const handleOnboardingStep = (step: keyof typeof onboardingState) => {
    localStorage.setItem(
      'onboardingState',
      JSON.stringify({
        ...onboardingState,
        [step]: true,
      })
    )

    setOnboardingState({
      ...onboardingState,
      [step]: true,
    })
  }

  useEffect(() => {
    if (onboardingState.step1 && onboardingState.step2) {
      // @ts-ignore
      updateUser.mutate({
        id: session?.user?.id!,
        onboarded: true,
      })
    }
  }, [onboardingState])

  const onboardingItems = [
    {
      icon: onboardingState.step1 ? (
        <CircleCheckIcon className="w-4 h-4 text-green-600" />
      ) : (
        <CircleIcon
          className="w-4 h-4 cursor-pointer"
          onClick={() => handleOnboardingStep('step1')}
        />
      ),
      label: (
        <Row className="gap-2">
          <p
            className="cursor-pointer"
            onClick={() => navigate(paths.userSettings)}
          >
            Download extension
          </p>

          <Button
            className="p-0 h-5 w-5"
            size="icon"
            variant="ghost"
            onClick={() => navigate(paths.userSettings)}
          >
            <ArrowRight className="w-4 h-4" />
          </Button>
        </Row>
      ),
    },
    {
      icon: onboardingState.step2 ? (
        <CircleCheckIcon className="w-4 h-4 text-green-600" />
      ) : (
        <CircleIcon
          className="w-4 h-4 cursor-pointer"
          onClick={() => handleOnboardingStep('step2')}
        />
      ),
      label: (
        <Row className="gap-2 w-full">
          <p
            className="cursor-pointer"
            onClick={() => navigate(paths.organization)}
          >
            Invite team members
          </p>

          <Button
            className="p-0 h-5 w-5"
            size="icon"
            variant="ghost"
            onClick={() => navigate(paths.organization)}
          >
            <ArrowRight className="w-4 h-4" />
          </Button>
        </Row>
      ),
    },
    // {
    //   icon: onboardingState.step3 ? (
    //     <CircleCheckIcon className="w-4 h-4 text-green-600" />
    //   ) : (
    //     <CircleIcon
    //       className="w-4 h-4 cursor-pointer"
    //       onClick={() => handleOnboardingStep('step3')}
    //     />
    //   ),
    //   label: (
    //     <Row className="gap-2">
    //       <p>Update tool usage</p>

    //       <Tooltip
    //         side="right"
    //         content={
    //           <Column className="gap-2">
    //             <p className="text-sm font-medium w-[320px]">
    //               After a day or so, click "Ask team" to gather insights about
    //               the tools.
    //             </p>
    //             {/* <Image className="h-[420px]" src={onboarding} alt="chrome" /> */}
    //           </Column>
    //         }
    //         delayDuration={0}
    //       >
    //         <Button
    //           variant="ghost"
    //           className="p-0 h-5 w-5"
    //           size="icon"
    //           onClick={() => navigate(paths.stack + '#untracked')}
    //         >
    //           <ArrowRight className="w-4 h-4" />
    //         </Button>
    //       </Tooltip>
    //     </Row>
    //   ),
    // },
    // {
    //   icon: onboardingState.step3 ? (
    //     <CircleCheckIcon className="w-4 h-4 text-green-600" />
    //   ) : (
    //     <CircleIcon
    //       className="w-4 h-4 cursor-pointer"
    //       onClick={() => handleOnboardingStep('step3')}
    //     />
    //   ),
    //   label: (
    //     <Row className="gap-2">
    //       <p>Sync extension</p>

    //       <Tooltip
    //         side="right"
    //         content={
    //           <Column className="gap-2">
    //             <p className="text-sm font-medium w-[320px]">
    //               Trigger the first scan for your tools. Wait 1-2 minutes. This
    //               will be done automatically every ten minutes.
    //             </p>
    //             <Image className="h-[420px]" src={onboarding} alt="chrome" />
    //           </Column>
    //         }
    //         delayDuration={0}
    //       >
    //         <Button variant="ghost" className="p-0 h-5 w-5" size="icon">
    //           <Eye className="w-4 h-4" />
    //         </Button>
    //       </Tooltip>
    //     </Row>
    //   ),
    // },
    // {
    //   icon: onboardingState.step4 ? (
    //     <CircleCheckIcon className="w-4 h-4 text-green-600" />
    //   ) : (
    //     <CircleIcon
    //       className="w-4 h-4 cursor-pointer"
    //       onClick={() => handleOnboardingStep('step4')}
    //     />
    //   ),
    //   label: (
    //     <Row className="gap-2">
    //       <p>Start tracking tools</p>

    //       <Button
    //         className="p-0 h-5 w-5"
    //         size="icon"
    //         variant="ghost"
    //         onClick={() => navigate(paths.stack + '#untracked')}
    //       >
    //         <ArrowRight className="w-4 h-4" />
    //       </Button>
    //     </Row>
    //   ),
    // },
    // {
    //   icon: onboardingState.step5 ? (
    //     <CircleCheckIcon className="w-4 h-4 text-green-600" />
    //   ) : (
    //     <CircleIcon
    //       className="w-4 h-4 cursor-pointer"
    //       onClick={() => handleOnboardingStep('step5')}
    //     />
    //   ),
    //   label: (
    //     <Row className="gap-2">
    //       <p>Wait for coworkers</p>

    //       <Tooltip
    //         side="right"
    //         content={
    //           <Column className="gap-2">
    //             <p className="text-sm font-medium w-[300px]">
    //               Your coworkers will receive an email to install the extension,
    //               and it will automatically sync their business tools and usage.
    //             </p>
    //           </Column>
    //         }
    //         delayDuration={0}
    //       >
    //         <Button variant="ghost" className="p-0 h-5 w-5" size="icon">
    //           <Eye className="w-4 h-4" />
    //         </Button>
    //       </Tooltip>
    //     </Row>
    //   ),
    // },
  ]

  return (
    <Column className="gap-2 w-full">
      <h2 className="text-sm font-semibold text-muted-foreground">
        Complete checklist
      </h2>

      {onboardingItems.map((item, idx) => (
        <Row key={idx} className="gap-2 items-center">
          {item.icon}

          <Column className="gap-1">
            <p className="text-[12px]">{item.label}</p>
          </Column>
        </Row>
      ))}
    </Column>
  )
}
