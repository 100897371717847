import { zodResolver } from '@hookform/resolvers/zod'
import { Building2, Crown, Pencil, Save, ShieldQuestion } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { FormInput, FormWrapper } from 'src/shared/components/FormComponents'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import { Card, CardTitle } from 'src/shared/components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'
import { getSubscriptionName } from 'src/shared/consts'
import { z } from 'zod'

import { useOrgsApi } from '../../api/hooks/apiOrgs'
import { TeamTable } from './Team/TeamTable'

const formSchema = z.object({
  name: z.string().min(2).max(50),
})

export const OrganizationPage = () => {
  const { orgId } = useOrgIdApi()
  const { organization, updateOrganization } = useOrgsApi({ orgId })

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
    },
  })

  useEffect(() => {
    const organization_ = organization?.data
    if (organization_) {
      methods.setValue('name', organization_.name || '')
    }
  }, [organization?.data])

  const onSubmitUserSettings = (values: z.infer<typeof formSchema>) =>
    updateOrganization.mutate({
      id: orgId || '',
      name: values.name,
    })

  return (
    <Column className="gap-4 w-full">
      <Row className="gap-2 items-center mb-1 py-1">
        <Building2 />
        <h1 className="text-2xl font-medium">Organization</h1>
      </Row>

      <Tabs defaultValue="team">
        <TabsList className="w-fit">
          <TabsTrigger value="team">Team</TabsTrigger>
          <TabsTrigger value="settings">Settings</TabsTrigger>
          <TabsTrigger value="faq">FAQ</TabsTrigger>
        </TabsList>

        <TabsContent value="team">
          <TeamTable />
        </TabsContent>

        <TabsContent value="settings">
          <Column className="gap-4">
            <Card className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white">
              <Column>
                <CardTitle>Current plan</CardTitle>

                <Badge className="bg-white text-black py-2 px-3 rounded-full mt-3 w-fit">
                  <Crown className="h-4 w-4 text-yellow-500 mr-1" />
                  {getSubscriptionName(organization?.data?.stripe_product_id)}
                </Badge>

                <Row className="gap-2 items-center mt-4">
                  <Pencil className="h-3 w-3" />
                  <a
                    className="text-sm text-white"
                    href="https://billing.stripe.com/p/login/aEU9BB8TTcLge1a3cc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Manage subscription
                  </a>
                </Row>
              </Column>
            </Card>

            {/* <Card className="w-full justify-center items-center">
              <CardTitle>Connect email accounts</CardTitle>

              <Column className="gap-2 w-full mt-5">hej</Column>
            </Card> */}

            <Card className="w-full justify-center items-center">
              <CardTitle>General settings</CardTitle>

              <Column className="gap-2 w-full mt-5">
                <FormWrapper onSubmit={onSubmitUserSettings} methods={methods}>
                  <FormInput name="name" label="Org. name" methods={methods} />

                  <Button style={{ marginTop: '1rem' }} type="submit">
                    <Save className="h-4 w-4 mr-2" />
                    Save
                  </Button>
                </FormWrapper>
              </Column>
            </Card>
          </Column>
        </TabsContent>

        <TabsContent value="faq">
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md font-medium ml-1">
                    What data do you store?
                  </h2>
                </Row>

                <p className="text-sm">
                  We try to store as little data as possible. For each extension
                  user, we store a list of user activity. Each user activity
                  contains: email, time of visit, and root domain of a tracked
                  app.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md font-medium ml-1">
                    How often does the extension scan?
                  </h2>
                </Row>

                <p className="text-sm">
                  The extension scans the activity every 10 minutes. We analyze
                  60 minutes back in time each scan. It usually takes about 10
                  seconds to scan, but up to two minutes if we find and add new
                  vendors.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md font-medium ml-1">
                    How do you identify the tools?
                  </h2>
                </Row>

                <p className="text-sm">
                  We use a privacy-first AI-based proprietary system to scan the
                  urls from the user activity and identify all business related
                  SaaS applications.
                </p>
              </Column>
            </Card>

            <Card>
              <Column className="gap-2">
                <Row>
                  <ShieldQuestion />
                  <h2 className="text-md font-medium ml-1">
                    How are you compliant and privacy aware?
                  </h2>
                </Row>

                <p className="text-sm">
                  Your full browser history is never stored in any database. We
                  only work with your data locally in the browser extension, and
                  not on our servers. We are fully GDPR compliant.
                </p>

                <p className="text-sm">
                  If you have any questions, reach out to us and we will be
                  happy to answer.
                </p>
              </Column>
            </Card>
          </div>
        </TabsContent>
      </Tabs>
    </Column>
  )
}
