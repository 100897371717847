import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Pencil, Plus, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Input } from 'src/shared/components/ui'

dayjs.extend(relativeTime)

interface Password {
  id: number
  email: string
  encryptedPassword: string
}

export const Passwords = () => {
  const [passwords, setPasswords] = useState<Password[]>([])
  console.log('🚀  passwords:', passwords)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [editingId, setEditingId] = useState<number | null>(null)
  const [masterPassword, setMasterPassword] = useState('')

  const deriveKey = (password: string) => {
    // Implement key derivation logic here (e.g., PBKDF2)
    setMasterPassword(password)
    return password // Placeholder, replace with actual key derivation
  }

  const encryptPassword = (password: string) => {
    const secretKey = deriveKey(masterPassword)
    return CryptoJS.AES.encrypt(password, secretKey).toString()
  }

  const handleAdd = () => {
    if (email && password && masterPassword) {
      const newPassword: Password = {
        id: Date.now(),
        email,
        encryptedPassword: encryptPassword(password),
      }
      setPasswords([...passwords, newPassword])
      setEmail('')
      setPassword('')
    }
  }

  const handleDelete = (id: number) => {
    setPasswords(passwords.filter((p) => p.id !== id))
  }

  const handleEdit = (id: number) => {
    const passwordToEdit = passwords.find((p) => p.id === id)
    if (passwordToEdit) {
      setEmail(passwordToEdit.email)
      setPassword('')
      setEditingId(id)
    }
  }

  const handleUpdate = () => {
    if (editingId && email && password) {
      setPasswords(
        passwords.map((p) =>
          p.id === editingId
            ? { ...p, email, encryptedPassword: encryptPassword(password) }
            : p
        )
      )
      setEmail('')
      setPassword('')
      setEditingId(null)
    }
  }

  return (
    <Column className="gap-4 w-full">
      <Card>
        <Row className="justify-between items-center mb-4">
          <p className="text-lg font-semibold">Passwords</p>
        </Row>

        <Row className="gap-2 mb-4">
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={editingId ? handleUpdate : handleAdd}>
            {editingId ? 'Update' : <Plus className="w-4 h-4" />}
          </Button>
        </Row>

        <Column className="gap-2">
          {passwords.map((p) => (
            <Row
              key={p.id}
              className="justify-between items-center p-2 border rounded"
            >
              <span>{p.email}</span>
              <Row className="gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleEdit(p.id)}
                >
                  <Pencil className="w-4 h-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleDelete(p.id)}
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </Row>
            </Row>
          ))}
        </Column>
      </Card>
    </Column>
  )
}
