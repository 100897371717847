import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { PlusCircle, RefreshCwOff } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'

import { AddSubscriptionSheet } from './AddSubscriptionSheet'
import { CurrentSubscription } from './CurrentSubscription'
import { ExpiredSubscriptions } from './ExpiredSubscriptions'

dayjs.extend(relativeTime)

export const Subscription = () => {
  const { tool_id } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })

  const tool = tools?.tools?.find((tool) => {
    return tool.id === Number(tool_id)
  })

  const CreateSubscription = () => (
    <Alert variant="default" className="mt-4">
      <Column c className="gap-2 p-4">
        <p className="text-gray-500 text-center">
          Enter subscription information to track spend, enable reminders, and
          monitor utilization, and more.
        </p>

        <Button className="mt-4" onClick={() => setIsOpen(true)}>
          <PlusCircle className="mr-2 w-4 h-4" />
          Add subscription
        </Button>
      </Column>
    </Alert>
  )

  return (
    <>
      <Column className="gap-4 w-full">
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3">
            <Card>
              <h2 className="text-lg font-semibold">Current subscription</h2>
              {tool?.active_subscriptions[0]?.status === 'ACTIVE' ? (
                <CurrentSubscription tool={tool as any} />
              ) : (
                <CreateSubscription />
              )}
            </Card>
          </div>

          <div className="col-span-2">
            <Card>
              <h2 className="text-lg font-semibold">Expired subscriptions</h2>
              {tool?.expired_subscriptions?.map((subscription) => (
                <ExpiredSubscriptions
                  key={subscription.id}
                  subscription={subscription}
                />
              ))}

              {tool?.expired_subscriptions?.length === 0 && (
                <Alert variant="default" className="mt-4">
                  <Row className="gap-2 items-center">
                    <RefreshCwOff className="h-4 w-4" />
                    <AlertDescription>
                      No expired subscriptions
                    </AlertDescription>
                  </Row>
                </Alert>
              )}
            </Card>
          </div>
        </div>
      </Column>

      <AddSubscriptionSheet isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
