import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export const Receipts = () => {
  const [files, setFiles] = useState<string[]>([])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const urls = acceptedFiles.map((file) => URL.createObjectURL(file))
    setFiles((prev) => [...prev, ...urls])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      {...getRootProps()}
      className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer ${
        isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
      }`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}

      {files.length > 0 && (
        <div className="mt-4">
          <h3>Uploaded Files:</h3>
          <ul>
            {files.map((fileUrl, index) => (
              <li key={index}>{fileUrl}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
