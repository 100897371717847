import { useQuery } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

import { useOrgIdApi } from './apiOrgId'
import { queryKeys } from './queryKeys'

export type InsertOverlappingTool =
  Database['public']['Tables']['overlapping_tool']['Insert']
export type UpdateOverlappingTool =
  Database['public']['Tables']['overlapping_tool']['Update']
export type OverlappingTool_ =
  Database['public']['Tables']['overlapping_tool']['Row']

export const useOverlappingTools = () => {
  const { orgId: org_id } = useOrgIdApi()
  const supabase = useSupabaseClient()

  const { data: overlappingTools } = useQuery({
    queryKey: [queryKeys.overlapping_tools],
    queryFn: async () =>
      await supabase
        .from('overlapping_tool')
        .select('*')
        .eq('organization_id', org_id!),
    enabled: !!org_id,
  })

  return {
    overlappingTools,
  }
}
