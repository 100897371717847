import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertUserActivity =
  Database['public']['Tables']['user_activity']['Insert']
export type UpdateUserActivity =
  Database['public']['Tables']['user_activity']['Update']
export type UserActivity_ = Database['public']['Tables']['user_activity']['Row']

export const useUserActivityApi = ({
  tool_id,
  org_id,
}: {
  tool_id?: number
  org_id?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: userActivitiesAll } = useQuery({
    queryKey: [queryKeys.user_activities],
    queryFn: async () =>
      await supabase
        .from('user_activity')
        .select(
          `*,
            org_user!inner(*, user(email)),
            tool(*, vendor(*))`
        )
        .eq('org_user.organization_id', org_id!)
        .order('last_visited', { ascending: false }),
    enabled: !!org_id,
  })

  const { data: userActivitiesForTool } = useQuery({
    queryKey: [queryKeys.user_activities, tool_id, org_id],
    queryFn: async () =>
      await supabase
        .from('user_activity')
        .select(
          `*,
            org_user!inner(*, user!org_user_user_id_fkey(*))`
        )
        .eq('tool_id', tool_id!)
        .eq('org_user.organization_id', org_id!)
        .order('last_visited', { ascending: false }),
    enabled: !!tool_id && !!org_id,
  })

  const updateUserActivity = useMutation({
    mutationFn: async (updateUserActivity: UpdateUserActivity) =>
      await supabase
        .from('user_activity')
        .update(updateUserActivity)
        .eq('id', updateUserActivity.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.user_activities] })

      toast({
        variant: 'success',
      })
    },
  })

  const deleteUserActivity = useMutation({
    mutationFn: async (id: string) =>
      await supabase.from('user_activity').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.user_activities] })
    },
  })

  return {
    userActivitiesAll,
    userActivitiesForTool,
    updateUserActivity,
    deleteUserActivity,
  }
}
