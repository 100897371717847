import { Pencil } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSubscriptionsApi } from 'src/api/hooks/apiSubscriptions'
import { Tool_ } from 'src/api/hooks/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

import { EditSubscriptionSheet } from './EditSubscriptionSheet'

export const CurrentSubscription = ({ tool }: { tool: Tool_ }) => {
  const { tool_id } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const { updateSubscription } = useSubscriptionsApi({
    tool_id,
  })

  const StatusSelect = () => (
    <Select
      value={tool.active_subscriptions[0]?.status || undefined}
      onValueChange={(value) => {
        updateSubscription.mutate({
          id: tool.active_subscriptions[0].id,
          status: value as 'ACTIVE' | 'EXPIRED',
          cancelled_at: new Date().toISOString(),
        })
      }}
    >
      <SelectTrigger className="w-[120px]">
        <SelectValue placeholder="Select status" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="ACTIVE">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-green-500"></div>
            <span className="text-sm font-medium">Active</span>
          </div>
        </SelectItem>

        <SelectItem value="EXPIRED">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-red-500"></div>
            <span className="text-sm font-medium">Expired</span>
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  )

  return (
    <>
      <Column className="gap-4 w-full">
        <Row className="gap-2 mt-4">
          <StatusSelect />

          <Button
            variant="outline"
            className="ml-auto"
            onClick={() => setIsOpen(true)}
          >
            <Pencil className="w-4 h-4 mr-2" />
            Edit
          </Button>
        </Row>

        <Table className="bg-gray-50 mt-4 rounded-md">
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">Renewal frequency</TableCell>
              <TableCell className="text-right">
                <Badge variant="outline" className="bg-blue-100 text-blue-800">
                  {tool.active_subscriptions[0]?.renewal_frequency}
                </Badge>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-medium">Next renewal</TableCell>
              <TableCell className="text-right">
                {`${tool.calculated.nextRenewalDate} (in ${tool.calculated.renewsIn} days)`}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-medium">Pricing model</TableCell>
              <TableCell className="text-right">
                <Badge
                  variant="outline"
                  className="bg-purple-100 text-purple-800"
                >
                  {tool.calculated.pricingModel}
                </Badge>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-medium">Monthly price</TableCell>
              <TableCell className="text-right">
                {tool.calculated.priceMonthFormatted}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-medium">Annualized spend</TableCell>
              <TableCell className="text-right">
                {tool.calculated.priceAnnualFormatted}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Column>

      <EditSubscriptionSheet
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        subscription_id={tool.active_subscriptions[0].id}
      />
    </>
  )
}
