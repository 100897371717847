import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertSubscription =
  Database['public']['Tables']['subscription']['Insert']
export type UpdateSubscription =
  Database['public']['Tables']['subscription']['Update']
export type Subscription_ = Database['public']['Tables']['subscription']['Row']

export const useSubscriptionsApi = ({
  subscription_id,
  tool_id,
}: {
  subscription_id?: number
  tool_id?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: subscriptions } = useQuery({
    queryKey: [queryKeys.subscriptions],
    queryFn: async () =>
      await supabase
        .from('subscription')
        .select('*, tool(id)')
        .eq('tool_id', tool_id!),
    enabled: !!tool_id,
  })

  const { data: subscription } = useQuery({
    queryKey: [queryKeys.subscriptions, subscription_id],
    queryFn: async () => {
      const res = await supabase
        .from('subscription')
        .select('*, tool(id)')
        .eq('id', subscription_id!)

      return res.data?.[0]
    },
    enabled: !!subscription_id,
  })

  const updateSubscription = useMutation({
    mutationFn: async (updateSubscription: UpdateSubscription) =>
      await supabase
        .from('subscription')
        .update(updateSubscription)
        .eq('id', updateSubscription.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Updated subscription',
      })
    },
  })

  const addSubscription = useMutation({
    mutationFn: async (subscription: InsertSubscription) =>
      await supabase.from('subscription').insert(subscription),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
    },
  })

  const deleteSubscription = useMutation({
    mutationFn: async ({ id }: { id: number }) =>
      await supabase.from('subscription').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
    },
  })

  return {
    subscription,
    subscriptions,
    updateSubscription,
    deleteSubscription,
    addSubscription,
  }
}
