import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useAuth } from 'src/shared/hooks/authProvider'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertOrganization =
  Database['public']['Tables']['organization']['Insert']
export type UpdateOrganization =
  Database['public']['Tables']['organization']['Update']

export const useOrgsApi = ({ orgId }: { orgId?: string }) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { session } = useAuth()

  const { data: organization } = useQuery({
    queryKey: [queryKeys.organization, orgId],
    queryFn: async () =>
      await supabase
        .from('organization')
        .select('*')
        .filter('id', 'eq', orgId)
        .single(),
    enabled: !!orgId,
  })

  const { data: organizations } = useQuery({
    queryKey: [queryKeys.organizations, session?.user?.id],
    queryFn: async () =>
      await supabase
        .from('org_user')
        .select('*, organization!inner(*)')
        .filter('user_id', 'eq', session?.user?.id!),
    enabled: !!session?.user?.id,
  })

  const createOrganization = useMutation({
    mutationFn: async (data: InsertOrganization) =>
      await supabase.from('organization').insert(data).select('id'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.organizations] })
    },
  })

  const updateOrganization = useMutation({
    mutationFn: async (updateData: UpdateOrganization) =>
      await supabase
        .from('organization')
        .update(updateData)
        .eq('id', updateData.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.organization] })

      toast({
        variant: 'success',
        title: 'Updated organization',
      })
    },
  })

  const deleteOrganization = useMutation({
    mutationFn: async (id: string) =>
      await supabase.from('organization').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.organizations] })
    },
  })

  return {
    organization,
    organizations,
    createOrganization,
    updateOrganization,
    deleteOrganization,
  }
}
