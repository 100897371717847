import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useAuth } from 'src/shared/hooks/authProvider'
import { Database } from 'supabase/database.types'

import { queryKeys } from './queryKeys'

export type InsertUser = Database['public']['Tables']['user']['Insert']
export type User_ = Database['public']['Tables']['user']['Row']

export const useUsersApi = () => {
  const queryClient = useQueryClient()
  const supabase = useSupabaseClient()
  const { session } = useAuth()

  const { data: user } = useQuery({
    queryKey: [queryKeys.user],
    queryFn: async () =>
      await supabase
        .from('user')
        .select('*')
        .eq('id', session?.user?.id!)
        .single(),
    enabled: !!session?.user?.id,
  })

  const updateUser = useMutation({
    mutationFn: async (insertUser: InsertUser) =>
      await supabase.from('user').update(insertUser).eq('id', insertUser.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.user] })
    },
  })

  return {
    user,
    updateUser,
  }
}
