import { AlertCircle } from 'lucide-react'
import { Bar, BarChart, XAxis, YAxis } from 'recharts'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { Row } from 'src/shared/components/Semantic/all'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'
import { Card } from 'src/shared/components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'src/shared/components/ui/chart'

import { chartConfig } from './consts'

export function Bars() {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId })
  let chartData = tools?.toolsStats?.chartData

  const top5Tools = chartData
    ? [...chartData].sort((a: any, b: any) => b.cost - a.cost).slice(0, 5)
    : []

  return (
    <Card className="gap-4 w-full h-fit">
      <h1 className="text-xl font-semibold">Spend by department</h1>
      <p className="text-sm font-normal text-muted-foreground">
        Annualized run-rate
      </p>

      {top5Tools.length ? (
        <ChartContainer config={chartConfig} className="h-[200px] mt-5 w-full">
          <BarChart
            accessibilityLayer
            data={top5Tools}
            layout="vertical"
            margin={{
              left: 0,
            }}
          >
            <YAxis
              interval={0}
              dataKey="tool"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) =>
                chartConfig[value as keyof typeof chartConfig]?.label
              }
            />
            <XAxis dataKey="cost" type="number" hide />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Bar dataKey="cost" layout="vertical" radius={5} />
          </BarChart>
        </ChartContainer>
      ) : (
        <Alert variant="default" className="mt-4">
          <Row className="gap-2 items-center">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              No data collected by your extension users yet
            </AlertDescription>
          </Row>
        </Alert>
      )}
    </Card>
  )
}
