import {
  ArrowLeft,
  ChartColumnBig,
  LucideCreditCard,
  Settings2,
  SquareKanban,
} from 'lucide-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/shared/components/ui/tabs'

import { Home } from './Home'
import { Passwords } from './Passwords'
import { Settings } from './Settings'
import { Subscription } from './Subscription'
import { Usage } from './Usage'

export const ToolPage = () => {
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tool = tools?.tools?.find((tool) => tool.id === Number(tool_id))

  const navigate = useNavigate()
  const location = useLocation()

  const currentTab = location.hash.slice(1) || 'overview'

  const handleTabChange = (value: string) => {
    navigate(`#${value}`)
  }

  return (
    <Column className="gap-4 w-full">
      <Column className="gap-4 w-full">
        <Row className="gap-2 items-center mb-1">
          <Button
            variant="white"
            onClick={() => navigate(paths.tools)}
            size="sm"
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back
          </Button>

          <SaasIcon
            toolName={tool?.vendor.name}
            src={tool?.vendor.logo_url || ''}
          />

          <h1 className="text-2xl font-medium">{tool?.vendor.name}</h1>
        </Row>

        <Tabs value={currentTab} onValueChange={handleTabChange}>
          <TabsList className="w-fit">
            <TabsTrigger value="overview">
              <SquareKanban className="w-4 h-4 mr-2" />
              Overview
            </TabsTrigger>

            <TabsTrigger value="subscription">
              <LucideCreditCard className="w-4 h-4 mr-2" />
              Subscription
            </TabsTrigger>

            <TabsTrigger value="usage">
              <ChartColumnBig className="w-4 h-4 mr-2" />
              Usage
            </TabsTrigger>

            <TabsTrigger value="settings">
              <Settings2 className="w-4 h-4 mr-2" />
              Settings
            </TabsTrigger>
          </TabsList>

          <TabsContent value="overview">
            <Home />
          </TabsContent>

          <TabsContent value="subscription">
            <Subscription />
          </TabsContent>

          <TabsContent value="usage">
            <Usage />
          </TabsContent>

          <TabsContent value="settings">
            <Settings />
          </TabsContent>

          <TabsContent value="passwords">
            <Passwords />
          </TabsContent>
        </Tabs>
      </Column>
    </Column>
  )
}
