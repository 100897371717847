import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Column } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { ArrowDown, ArrowDownUp, ArrowUp, TrashIcon } from 'lucide-react'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { Row } from 'src/shared/components/Semantic/all'
import { Badge } from 'src/shared/components/ui/badge'
import { Button } from 'src/shared/components/ui/button'
import { Checkbox } from 'src/shared/components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'
import { Roles, roleMap } from 'src/shared/consts'

import { TeamMember } from './consts'

const SortableHeader = ({
  column,
  children,
}: {
  column: Column<TeamMember, unknown>
  children: React.ReactNode
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Row
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          className="cursor-pointer items-center"
        >
          {children}
          {column.getIsSorted() === 'asc' ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : column.getIsSorted() === 'desc' ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDownUp className="ml-2 h-4 w-4" />
          )}
        </Row>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          Sort Ascending
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          Sort Descending
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const useColumns = () => {
  const queryClient = useQueryClient()
  const { updateOrgUser } = useOrgUsersApi()

  return [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={Boolean(
            table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
          )}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          label={''}
          setChecked={() => {}}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          label={''}
          setChecked={() => {}}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <SortableHeader column={column}>Name</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <SortableHeader column={column}>Email</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('email')}</div>,
    },
    {
      accessorKey: 'role_id',
      header: ({ column }) => (
        <SortableHeader column={column}>Role</SortableHeader>
      ),
      cell: ({ row }) => (
        <Row className="gap-1">
          <Badge variant="outline">{roleMap[row.getValue('role_id')]}</Badge>
        </Row>
      ),
    },
    {
      accessorKey: 'joined',
      header: ({ column }) => (
        <SortableHeader column={column}>Joined</SortableHeader>
      ),
      cell: ({ row }) => (
        <div>{dayjs(row.getValue('joined')).format('MMM D, YYYY')}</div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const teamMember = row.original
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <DotsHorizontalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
              <DropdownMenuItem
                disabled={teamMember.role_id === Roles.SUPERADMIN}
                onClick={async () => {
                  await updateOrgUser
                    // @ts-ignore
                    .mutateAsync({
                      id: teamMember.org_user_id,
                      removed: true,
                    })
                    .then(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.org_users],
                      })
                    })
                }}
              >
                <TrashIcon className="mr-2 h-4 w-4" />
                Remove from org
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
}
