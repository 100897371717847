import { Blocks, Building2, FlaskConical, LayoutDashboard } from 'lucide-react'
import { paths } from 'src/routes/paths'

export const links = [
  {
    label: 'Dashboard',
    href: paths.dashboard,
    icon: (
      <LayoutDashboard className="text-neutral-700 h-5 w-5 flex-shrink-0" />
    ),
  },
  {
    label: 'Tools',
    href: paths.tools,
    icon: <Blocks className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Insights',
    href: paths.insights,
    icon: <FlaskConical className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
  {
    label: 'Organization',
    href: paths.organization,
    icon: <Building2 className="text-neutral-700 h-5 w-5 flex-shrink-0" />,
  },
]
