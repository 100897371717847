import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Banknote, ExternalLink, Users } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useOrgUsersApi } from 'src/api/hooks/apiOrgUsers'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/shared/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'
import { departmentOptions } from 'src/shared/consts'

import { StatCard } from '../Dashboard/StatCard'
import { FileUpload } from './components/FileUpload'
import { UsersBarChart } from './components/UsersBarChart'

dayjs.extend(relativeTime)

export const Home = () => {
  const navigate = useNavigate()
  const { tool_id } = useParams()
  const { orgId } = useOrgIdApi()
  const { tools, updateTool } = useToolsApi({ organization_id: orgId || '' })
  const { orgUsers } = useOrgUsersApi()
  const tool = tools?.tools?.find((tool) => tool.id === Number(tool_id))

  const budgetOwner = useMemo(() => tool?.budget_owner.id, [tool?.budget_owner])
  const department = useMemo(() => tool?.department, [tool?.department])

  const SelectBudgetOwner = () => {
    return (
      <Select
        value={budgetOwner?.toString() || '-'}
        onValueChange={(value) => {
          updateTool.mutate({
            id: tool?.id!,
            owner_org_user_id: value === '-' ? null : Number(value),
          })
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Budget owner" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem key="-" value="-">
            No budget owner
          </SelectItem>

          {orgUsers?.data?.map((user) => (
            <SelectItem key={user.id} value={user.id.toString()}>
              {user.user?.email?.slice(0, 12) + '...'}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  const SelectDepartment = () => {
    return (
      <Select
        onValueChange={(value) => {
          updateTool.mutate({
            id: tool?.id!,
            department: value === '-' ? null : value,
          })
        }}
        defaultValue={department || '-'}
      >
        <SelectTrigger>
          <SelectValue placeholder="No department" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem key="-" value="-">
            No department
          </SelectItem>

          {departmentOptions.map((department) => (
            <SelectItem key={department.value} value={department.value || ''}>
              {department.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  return (
    <Column className="gap-4 w-full">
      <div className="grid grid-cols-3 w-full gap-4">
        <Column className="gap-4 col-span-2">
          <Row className="gap-4">
            <Card>
              <Row className="gap-4">
                <Column className="gap-2 min-w-[140px]">
                  <p className="text-sm font-semibold text-muted-foreground">
                    Budget owner
                  </p>

                  {SelectBudgetOwner()}
                </Column>

                <Column className="gap-2 min-w-[140px]">
                  <p className="text-sm font-semibold text-muted-foreground">
                    Department
                  </p>

                  {SelectDepartment()}
                </Column>
              </Row>
            </Card>

            <StatCard
              title="Active users"
              value={tool?.calculated?.userCount || 0}
              icon={<Users className="w-6 h-6 text-muted-foreground" />}
              description="This month"
            />

            <StatCard
              title="Total spend"
              value={'$' + tool?.calculated?.priceAnnual || 0}
              icon={<Banknote className="w-6 h-6 text-muted-foreground" />}
              description="Per year" // Annualized
            />
          </Row>

          <UsersBarChart />
        </Column>

        <Column className="gap-4 col-span-1">
          <Card>
            <Row className="justify-between items-center">
              <p className="text-lg font-semibold">Subscription</p>
              <Button
                variant="outline"
                onClick={() => navigate('#subscription')}
              >
                Manage
              </Button>
            </Row>
            {tool?.active_subscriptions &&
            tool?.active_subscriptions?.length > 0 ? (
              <>
                <Row className="gap-2 mt-4">
                  <Badge
                    variant="outline"
                    className="bg-green-100 text-green-800"
                  >
                    {tool?.active_subscriptions[0]?.status}
                  </Badge>

                  <Badge
                    variant="outline"
                    className="bg-purple-100 text-purple-800"
                  >
                    {tool?.calculated?.pricingModel}
                  </Badge>
                </Row>

                <Table className="bg-gray-50 mt-4 rounded-md">
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        Next renewal
                      </TableCell>
                      <TableCell className="text-right">
                        In {tool?.calculated?.renewsIn} days
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className="font-medium">Price</TableCell>
                      <TableCell className="text-right">
                        {tool?.calculated?.priceMonthFormatted}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            ) : (
              <p className="text-sm text-muted-foreground">
                No active subscriptions
              </p>
            )}
          </Card>

          <Card>
            <Row className="justify-between items-center mb-2">
              <p className="text-lg font-semibold">Attachments</p>
            </Row>

            <FileUpload
              onUploadComplete={(urls) => {
                updateTool.mutate({
                  id: tool?.id!,
                  file_urls: urls,
                })
              }}
              uploadedFiles={
                tool?.file_urls ? tool.file_urls.split(',').filter(Boolean) : []
              }
              maxFiles={5}
              maxSize={10 * 1024 * 1024} // 10 MB
            />
          </Card>

          <Card>
            <p className="text-lg font-semibold">
              About {tool?.vendor.name || 'About Tool'}
            </p>

            <p className="text-sm text-muted-foreground mt-1">
              {tool?.vendor.description || 'No description available'}
            </p>

            <Badge variant="secondary" className="px-2 py-1 mt-2">
              Tracking {tool?.vendor.root_domain}
            </Badge>

            <Row>
              <Button
                variant="outline"
                className="mt-4"
                onClick={() => window.open(tool?.vendor.url || '', '_blank')}
              >
                <ExternalLink className="w-4 h-4 mr-2" />
                Website
              </Button>

              <Button
                variant="outline"
                className="mt-4 ml-2"
                onClick={() =>
                  window.open(tool?.vendor.link_to_pricing_page || '', '_blank')
                }
              >
                <ExternalLink className="w-4 h-4 mr-2" />
                Pricing
              </Button>
            </Row>
          </Card>
        </Column>
      </div>
    </Column>
  )
}
