import { useToolsApi } from '../apiTools'

export const useUntrackedTools = (orgId: string) => {
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const untrackedTools = tools?.tools?.filter(
    (tool) => tool.status === 'not_in_stack'
  )

  const untrackedTools_ =
    untrackedTools?.filter((tool) => tool.status === 'not_in_stack')?.length ||
    0
  return untrackedTools_
}
