import { CreditCard, Ellipsis, MousePointerClick, Users } from 'lucide-react'
import { Row } from 'src/shared/components/Semantic/all'

export const pricingModelOptions = [
  {
    value: 'FLAT_FEE',
    label: (
      <Row className="items-center gap-2">
        <CreditCard className="w-4 h-4" />
        <p>Flat fee</p>
      </Row>
    ),
  },
  {
    value: 'PER_SEAT',
    label: (
      <Row className="items-center gap-2">
        <Users className="w-4 h-4" />
        <p>Per seat</p>
      </Row>
    ),
  },
  {
    value: 'USAGE_BASED',
    label: (
      <Row className="items-center gap-2">
        <MousePointerClick className="w-4 h-4" />
        <p>Usage based</p>
      </Row>
    ),
  },
  {
    value: 'OTHER',
    label: (
      <Row className="items-center gap-2">
        <Ellipsis className="w-4 h-4" />
        <p>Other</p>
      </Row>
    ),
  },
]

export const renewalFrequencyOptions = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'YEARLY', label: 'Yearly' },
]
