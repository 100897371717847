import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Trash2, Undo2 } from 'lucide-react'
import { useParams } from 'react-router-dom'
import {
  Subscription_,
  useSubscriptionsApi,
} from 'src/api/hooks/apiSubscriptions'
import { Column } from 'src/shared/components/Semantic/all'
import { Button, Tooltip } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

import { pricingModelMap } from './consts'

dayjs.extend(relativeTime)

export const ExpiredSubscriptions = ({
  subscription,
}: {
  subscription: Subscription_
}) => {
  const { tool_id } = useParams()
  const { updateSubscription, deleteSubscription } = useSubscriptionsApi({
    tool_id,
  })

  const handleRemove = () => {
    deleteSubscription.mutate({
      id: subscription.id,
    })
  }

  const handleReactivate = () => {
    updateSubscription.mutate({
      id: subscription.id,
      status: 'ACTIVE',
      cancelled_at: null,
    })
  }

  return (
    <Column className="gap-4 w-full">
      <Table className="bg-gray-50 mt-4 rounded-md">
        <TableHeader>
          <TableRow>
            <TableHead>Type</TableHead>
            <TableHead>Start Date</TableHead>
            <TableHead>End Date</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell>
              <Badge className="bg-purple-100 text-purple-800">
                {pricingModelMap[subscription.pricing_model || '']}
              </Badge>
            </TableCell>

            <TableCell>
              {subscription.starts_at
                ? dayjs(subscription.starts_at).format('MMM D, YYYY')
                : 'N/A'}
            </TableCell>

            <TableCell>
              {subscription.cancelled_at
                ? dayjs(subscription.cancelled_at).format('MMM D, YYYY')
                : 'N/A'}
            </TableCell>

            <TableCell className="flex items-center gap-0">
              <Tooltip content="Remove subscription" delayDuration={0}>
                <Button variant="ghost" size="sm" onClick={handleRemove}>
                  <Trash2 className="w-4 h-4" />
                </Button>
              </Tooltip>
              <Tooltip content="Reactivate" delayDuration={0}>
                <Button variant="ghost" size="sm" onClick={handleReactivate}>
                  <Undo2 className="w-4 h-4" />
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Column>
  )
}
