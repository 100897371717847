import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { Column } from '@tanstack/react-table'
import {
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  CheckCircleIcon,
  XCircleIcon,
} from 'lucide-react'
import { Link } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui/dropdown-menu'

import { Application } from './consts'

const SortableHeader = ({
  column,
  children,
}: {
  column: Column<Application, unknown>
  children: React.ReactNode
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Row
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          className="cursor-pointer items-center"
        >
          {children}
          {column.getIsSorted() === 'asc' ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : column.getIsSorted() === 'desc' ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowDownUp className="ml-2 h-4 w-4" />
          )}
        </Row>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
          Sort Ascending
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
          Sort Descending
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const useColumns = () => {
  const { orgId } = useOrgIdApi()
  const { updateTool } = useToolsApi({
    organization_id: orgId,
  })

  return [
    {
      accessorKey: 'app',
      header: ({ column }) => (
        <SortableHeader column={column}>Application</SortableHeader>
      ),
      cell: ({ row }) => (
        <Link
          className="font-semibold cursor-pointer flex items-center gap-2"
          to={`/tools/${row.original.app.id}`}
        >
          <SaasIcon
            toolName={row.original.app.name}
            src={row.original.app.logo_url || ''}
            size="sm"
          />
          {row.original.app.name}
        </Link>
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.app.name.localeCompare(rowB.original.app.name)
      },
      accessorFn: (row) => row.app.name,
    },
    {
      accessorKey: 'dept',
      header: ({ column }) => (
        <SortableHeader column={column}>Department</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('dept')}</div>,
    },
    {
      accessorKey: 'owner',
      header: ({ column }) => (
        <SortableHeader column={column}>Owner</SortableHeader>
      ),
      cell: ({ row }) => <div>{row.getValue('owner')}</div>,
    },
    {
      accessorKey: 'MAUs',
      header: ({ column }) => (
        <SortableHeader column={column}>MAUs</SortableHeader>
      ),
      cell: ({ row }) => (
        <div className="text-right">{row.getValue('MAUs')}</div>
      ),
    },
    {
      accessorKey: 'spendMonthly',
      header: ({ column }) => (
        <SortableHeader column={column}>Monthly spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendMonthly'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right font-medium">{formatted}</div>
      },
    },
    {
      accessorKey: 'spendAnnual',
      header: ({ column }) => (
        <SortableHeader column={column}>Annual spend</SortableHeader>
      ),
      cell: ({ row }) => {
        const spend = parseFloat(row.getValue('spendAnnual'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(spend)
        return <div className="text-right font-medium">{formatted}</div>
      },
    },
    {
      accessorKey: 'tracking',
      header: ({ column }) => (
        <SortableHeader column={column}>Tracking</SortableHeader>
      ),
      cell: ({ row }) => (
        <div className="capitalize flex items-center gap-2">
          {row.getValue('tracking') ? (
            <>
              <CheckCircleIcon className="h-4 w-4 text-green-500" />
              <span>Tracking</span>
            </>
          ) : (
            <>
              <XCircleIcon className="h-4 w-4 text-red-500" />
              <span>Not tracking</span>
            </>
          )}
        </div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const application = row.original

        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => {
                    updateTool.mutate({
                      id: application.app.id,
                      is_tracking: false,
                      status: 'not_in_stack',
                    })
                  }}
                >
                  Move to untracked
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        )
      },
    },
  ]
}
