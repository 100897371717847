import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { pricingModelMap } from 'src/pages/Tool/Subscription/consts'

import { Subscription_ } from '../../apiSubscriptions'
import { UserActivity_ } from '../../apiUserActivity'
import { getToolStats } from './getToolStats'
import {
  calculateAnnualPrice,
  calculateAnnualPriceFormatted,
  calculateMonthlyPrice,
  calculateMonthlyPriceFormatted,
  calculateQuarterlyPrice,
  calculateQuarterlyPriceFormatted,
} from './utils'

dayjs.extend(relativeTime)

export const calculateActiveSubscription = (
  active_subscriptions?: Subscription_[],
  user_activities?: UserActivity_[]
) => {
  const active_subscription: Subscription_ = active_subscriptions?.[0] as any

  const { monthlyUserCount, userCount, monthlyUserVisits } =
    getToolStats(user_activities)
  const orgUserIds = user_activities?.map(({ org_user_id }) => org_user_id) // TODO
  const next_renewal_date = dayjs(active_subscription?.next_renewal_date)

  let renewsIn
  let nextRenewalDateFormatted

  if (active_subscription?.next_renewal_date) {
    const currentMonth = dayjs().month() // January is 0
    let yearNumber = Number(next_renewal_date.format('YYYY'))
    let monthNumber = Number(next_renewal_date.format('M')) - 1 // Convert to 0-based month
    let dayNumber = Number(next_renewal_date.format('D'))

    if (active_subscription?.renewal_frequency === 'MONTHLY') {
      if (monthNumber !== currentMonth) {
        monthNumber = currentMonth

        // Handle year rollover
        if (monthNumber === 12) {
          monthNumber = 0
          yearNumber++
        }
      }
    }

    if (active_subscription?.renewal_frequency === 'QUARTERLY') {
      monthNumber = monthNumber + 3

      // Handle year rollover
      if (monthNumber === 12) {
        monthNumber = 0
        yearNumber++
      }
    }

    if (active_subscription?.renewal_frequency === 'YEARLY') {
      yearNumber = yearNumber + 1
    }

    const nextRenewalDate = dayjs()
      .year(yearNumber)
      .month(monthNumber)
      .date(dayNumber)

    renewsIn = nextRenewalDate.diff(dayjs(), 'days')
    nextRenewalDateFormatted = nextRenewalDate.format('MMM D, YYYY')
  }

  return {
    pricingModel: pricingModelMap[active_subscription?.pricing_model || ''],
    renewsIn,
    nextRenewalDate: nextRenewalDateFormatted,
    startsAt: active_subscription?.starts_at
      ? dayjs(active_subscription?.starts_at).format('MMM D, YYYY')
      : null,
    cancelledAt: active_subscription?.cancelled_at
      ? dayjs(active_subscription?.cancelled_at).format('MMM D, YYYY')
      : null,

    priceMonth: calculateMonthlyPrice(active_subscription),
    priceQuarter: calculateQuarterlyPrice(active_subscription),
    priceAnnual: calculateAnnualPrice(active_subscription),

    priceMonthFormatted: calculateMonthlyPriceFormatted(active_subscription),
    priceQuarterFormatted:
      calculateQuarterlyPriceFormatted(active_subscription),
    priceAnnualFormatted: calculateAnnualPriceFormatted(active_subscription),

    orgUserIds,
    userCount,
    monthlyUserCount,
    monthlyUserVisits,
  }
}
