import { Subscription_ } from '../../apiSubscriptions'
import { divisorMap } from './consts'

// ------- Base calculations -------
export const calculateMonthlyPrice = (subscription?: Subscription_) => {
  if (!subscription) return 0

  let monthlyPrice = 0
  const divisor = divisorMap[subscription.renewal_frequency!] // Divide by 12, 4, or 1

  if (subscription?.pricing_model === 'PER_SEAT') {
    const pricePerSeat = subscription?.price_per_seat!
    const numberOfSeats = subscription?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    monthlyPrice = totalPrice / divisor
  }

  if (subscription?.pricing_model === 'FLAT_FEE')
    monthlyPrice = subscription?.flat_fee_cost! / divisor

  if (subscription?.pricing_model === 'USAGE_BASED')
    monthlyPrice = subscription?.usage_based_cost! / divisor

  if (subscription?.pricing_model === 'OTHER')
    monthlyPrice = subscription?.other_cost! / divisor

  return monthlyPrice
}

export const calculateQuarterlyPrice = (subscription?: Subscription_) =>
  calculateMonthlyPrice(subscription) * 4

export const calculateAnnualPrice = (subscription?: Subscription_) =>
  calculateMonthlyPrice(subscription) * 12

// ------- Formatted calculations -------
export const calculateMonthlyPriceFormatted = (
  subscription?: Subscription_
) => {
  if (!subscription) return 'N/A'

  const monthlyPrice = Math.round(calculateMonthlyPrice(subscription))
  let formattedPrice = 'N/A'

  if (subscription?.pricing_model === 'FLAT_FEE')
    formattedPrice = `$${monthlyPrice} / month`

  if (subscription?.pricing_model === 'PER_SEAT') {
    const pricePerSeat = subscription?.price_per_seat!
    const numberOfSeats = subscription?.number_of_seats!
    const totalPrice = pricePerSeat * numberOfSeats

    formattedPrice = `($${pricePerSeat} x ${numberOfSeats} seats) $${totalPrice} / month`
  }

  if (subscription?.pricing_model === 'USAGE_BASED')
    formattedPrice = `$${monthlyPrice} / month`

  if (subscription?.pricing_model === 'OTHER')
    formattedPrice = `$${monthlyPrice} / month`

  return formattedPrice
}

export const calculateQuarterlyPriceFormatted = (
  subscription: Subscription_
) => {
  if (!subscription) return 'N/A'

  const quarterlyPrice = Math.fround(calculateQuarterlyPrice(subscription))
  let formattedPrice = 'N/A'

  if (subscription?.pricing_model === 'FLAT_FEE')
    formattedPrice = `$${quarterlyPrice} / quarter`

  if (subscription?.pricing_model === 'PER_SEAT')
    formattedPrice = `($${quarterlyPrice} x 4) $${quarterlyPrice} / quarter`

  if (subscription?.pricing_model === 'USAGE_BASED')
    formattedPrice = `$${quarterlyPrice} / quarter`

  if (subscription?.pricing_model === 'OTHER')
    formattedPrice = `$${quarterlyPrice} / quarter`

  return formattedPrice
}

export const calculateAnnualPriceFormatted = (subscription?: Subscription_) => {
  if (!subscription) return 'N/A'
  const annualPrice = Math.round(calculateAnnualPrice(subscription))

  return `$${annualPrice} / year`
}
