import { useQueryClient } from '@tanstack/react-query'
import {
  AlertCircle,
  Blend,
  ChartColumnDecreasing,
  RefreshCcw,
  UserRoundX,
  Users,
} from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import {
  OverlappingTool_,
  useOverlappingTools,
} from 'src/api/hooks/apiOverlappingTools'
import { useToolsApi } from 'src/api/hooks/apiTools'
import { queryKeys } from 'src/api/hooks/queryKeys'
import { useGenerateOverlappingTools } from 'src/api/server/useGenerateOverlappingTools'
import { paths } from 'src/routes/paths'
import { SaasIcon } from 'src/shared/components/SaasIcon'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, ScrollArea, Tooltip } from 'src/shared/components/ui'
import { Alert, AlertDescription } from 'src/shared/components/ui/alert'
import { Badge } from 'src/shared/components/ui/badge'
import { Progress } from 'src/shared/components/ui/progress'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'src/shared/components/ui/table'

export const CostReduction = () => {
  const { orgId } = useOrgIdApi()
  const { tools } = useToolsApi({ organization_id: orgId || '' })
  const tools_ = useMemo(() => tools, [tools])
  const navigate = useNavigate()
  const generateOverlappingTools = useGenerateOverlappingTools()
  const { overlappingTools } = useOverlappingTools()
  const queryClient = useQueryClient()

  const OverlappingTool = ({ tool }: { tool: OverlappingTool_ }) => {
    const overlappingTools: any = tool.overlappingtools

    return (
      <Card className="h-[220px] p-4 bg-slate-50">
        <h1 className="text-md font-medium text-gray-700">{tool.title}</h1>

        <ScrollArea className="h-[40px] mt-2">
          <Row className="gap-2 mt-2 w-fit flex-wrap">
            {overlappingTools?.map((tool) => (
              <Badge className="bg-gradient-to-r from-blue-500 to-purple-500 text-white border-none">
                {tool}
              </Badge>
            ))}
          </Row>
        </ScrollArea>

        <ScrollArea className="h-[100px] mt-2">
          <p className="text-sm text-muted-foreground">{tool.description}</p>
        </ScrollArea>
      </Card>
    )
  }

  const SeatUtilization = () => {
    const tools = tools_?.tools
      ?.filter((tool) => !tool.is_desktop_tool)
      .filter((tool) => {
        const userCount = tool.calculated?.userCount
        const numberOfSeats =
          tool.active_subscriptions?.[0]?.number_of_seats || 0
        const utilization = (userCount / numberOfSeats) * 100

        return (
          tool.status === 'in_stack' &&
          tool.active_subscriptions?.[0]?.pricing_model === 'PER_SEAT' &&
          utilization < 100
        )
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <Users className="w-5 h-5" />
            <p className="text-lg font-semibold">Seat utilization</p>
          </Row>

          <Alert variant="default" className="mt-4">
            <Row className="gap-2 items-center">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                There are no tools with low seat utilization
              </AlertDescription>
            </Row>
          </Alert>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <Users className="w-5 h-5" />
          <p className="text-lg font-semibold">Seat utilization</p>
        </Row>

        <ScrollArea className={`h-[${tools?.length > 3 ? 350 : 250}px]`}>
          <Table className="bg-gray-50 mt-4 rounded-md">
            <TableBody>
              <TableRow className="bg-white sticky top-0 z-10">
                <TableCell className="font-medium">Tool</TableCell>
                <TableCell className="font-medium">Seat utilization</TableCell>
                <TableCell className="font-medium">Monthly waste</TableCell>
                <TableCell className="font-medium">Annual waste</TableCell>
              </TableRow>

              {tools?.map((tool) => {
                const userCount = tool.calculated?.userCount
                const pricePerSeat =
                  tool.active_subscriptions?.[0]?.price_per_seat || 0
                const numberOfSeats =
                  tool.active_subscriptions?.[0]?.number_of_seats || 0
                const utilization = Math.round(
                  (userCount / numberOfSeats) * 100
                )
                const monthlyWaste = (numberOfSeats - userCount) * pricePerSeat

                return (
                  <TableRow className="bg-white">
                    <TableCell
                      className="font-medium cursor-pointer"
                      onClick={() => navigate(paths.tools + '/' + tool.id)}
                    >
                      <Row className="items-center gap-2">
                        <SaasIcon
                          toolName={tool.vendor.name}
                          src={tool.vendor.logo_url || ''}
                          size="sm"
                        />
                        {tool.vendor.name}
                      </Row>
                    </TableCell>

                    <TableCell className="font-medium">
                      <Row className="items-center gap-2">
                        <Progress
                          value={utilization}
                          indicatorColor={
                            utilization >= 100
                              ? 'bg-green-500'
                              : utilization >= 50
                              ? 'bg-orange-500'
                              : 'bg-red-500'
                          }
                        />
                        <p className="text-sm">
                          {userCount}/{numberOfSeats}
                        </p>
                      </Row>
                    </TableCell>

                    <TableCell className="font-medium">
                      ${Math.abs(monthlyWaste).toFixed(2)}
                    </TableCell>

                    <TableCell className="font-medium">
                      ${(Math.abs(monthlyWaste) * 12).toFixed(2)}
                    </TableCell>
                  </TableRow>
                )
              })}

              <TableRow className="bg-white">
                <TableCell className="font-normal text-muted-foreground">
                  Total
                </TableCell>
                <TableCell className="font-medium"></TableCell>
                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    $
                    {tools_?.toolsStats?.totalMonthlyWasteSeatUtilization?.toFixed(
                      2
                    )}
                  </Badge>
                </TableCell>
                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    $
                    {tools_?.toolsStats?.totalAnnualWasteSeatUtilization?.toFixed(
                      2
                    )}
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const LowUserCount = () => {
    const tools = tools_?.tools
      ?.filter((tool) => !tool.is_desktop_tool)
      .filter((tool) => {
        const active_users = tool.calculated?.userCount
        const total_users = tools_?.toolsStats?.totalActiveUsers || 0
        const utilization = (active_users / total_users) * 100

        return tool.status === 'in_stack' && utilization < 50
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <UserRoundX className="w-5 h-5" />
            <p className="text-lg font-semibold">Low user count</p>
            <Badge variant="secondary">&lt;50% of employees are using</Badge>
          </Row>

          <Alert variant="default" className="mt-4">
            <Row className="gap-2 items-center">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                There are no tools with low user count
              </AlertDescription>
            </Row>
          </Alert>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <UserRoundX className="w-5 h-5" />
          <p className="text-lg font-semibold">Low user count</p>
          <Badge variant="secondary">&lt;50% of employees are using</Badge>
        </Row>

        <ScrollArea className={`h-[${tools?.length > 3 ? 350 : 250}px]`}>
          <Table className="bg-gray-50 mt-4 rounded-md">
            <TableBody>
              <TableRow className="bg-gray-50 sticky top-0 z-10">
                <TableCell className="font-medium">Tool</TableCell>
                <TableCell className="font-medium">Active users</TableCell>
                <TableCell className="font-medium">Monthly waste</TableCell>
                <TableCell className="font-medium">Annual waste</TableCell>
              </TableRow>

              {tools
                ?.sort((a, b) => {
                  const a_users = a.calculated?.userCount
                  const b_users = b.calculated?.userCount
                  const total = tools_?.toolsStats?.totalActiveUsers || 0
                  const a_util = (a_users / total) * 100
                  const b_util = (b_users / total) * 100
                  return a_util - b_util
                })
                .map((tool) => {
                  const active_users = tool.calculated?.userCount
                  const total_users = tools_?.toolsStats?.totalActiveUsers || 0
                  const utilization = Math.round(
                    (active_users / total_users) * 100
                  )

                  return (
                    <TableRow className="bg-white">
                      <TableCell
                        className="font-medium cursor-pointer"
                        onClick={() => navigate(paths.tools + '/' + tool.id)}
                      >
                        <Row className="items-center gap-2">
                          <SaasIcon
                            toolName={tool.vendor.name}
                            src={tool.vendor.logo_url || ''}
                            size="sm"
                          />
                          {tool.vendor.name}
                        </Row>
                      </TableCell>

                      <TableCell className="font-medium">
                        <Row className="items-center gap-2">
                          <Badge className="bg-red-100 text-red-800">
                            {utilization}% ({active_users} users)
                          </Badge>
                        </Row>
                      </TableCell>

                      <TableCell className="font-medium">
                        ${tool.calculated?.priceMonth}
                      </TableCell>

                      <TableCell className="font-medium">
                        ${tool.calculated?.priceAnnual}
                      </TableCell>
                    </TableRow>
                  )
                })}

              <TableRow className="bg-white">
                <TableCell className="font-normal text-muted-foreground">
                  Total
                </TableCell>
                <TableCell className="font-medium"></TableCell>
                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    ${tools_?.toolsStats?.totalMonthlyWasteLowUsage?.toFixed(2)}
                  </Badge>
                </TableCell>
                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    ${tools_?.toolsStats?.totalAnnualWasteLowUsage?.toFixed(2)}
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const LowUsage = () => {
    const tools = tools_?.tools
      ?.filter((tool) => !tool.is_desktop_tool)
      .filter((tool) => {
        const monthlyVisits = tool.calculated?.monthlyUserVisits
        return tool.status === 'in_stack' && monthlyVisits < 10
      })

    if (!tools?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <ChartColumnDecreasing className="w-5 h-5" />
            <p className="text-lg font-semibold">Low usage</p>
            <Badge variant="secondary">&lt;10 total visits/month</Badge>
          </Row>

          <Alert variant="default" className="mt-4">
            <Row className="gap-2 items-center">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                There are no tools with low usage
              </AlertDescription>
            </Row>
          </Alert>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <ChartColumnDecreasing className="w-5 h-5" />
          <p className="text-lg font-semibold">Low usage</p>
          <Badge variant="secondary">&lt;10 total visits/month</Badge>
        </Row>

        <ScrollArea className="h-[350px]">
          <Table className="bg-gray-50 mt-4 rounded-md">
            <TableBody>
              <TableRow className="bg-gray-50 sticky top-0 z-10">
                <TableCell className="font-medium">Tool</TableCell>
                <TableCell className="font-medium">Active users</TableCell>
                <TableCell className="font-medium">Monthly waste</TableCell>
                <TableCell className="font-medium">Annual waste</TableCell>
              </TableRow>

              {tools
                ?.filter((tool) => tool.calculated?.monthlyUserVisits < 10)
                .sort((a, b) => {
                  const a_visits: any = a.calculated?.monthlyUserVisits
                  const b_visits: any = b.calculated?.monthlyUserVisits
                  return a_visits - b_visits
                })
                .map((tool) => {
                  return (
                    <TableRow className="bg-white">
                      <TableCell
                        className="font-medium cursor-pointer"
                        onClick={() => navigate(paths.tools + '/' + tool.id)}
                      >
                        <Row className="items-center gap-2">
                          <SaasIcon
                            toolName={tool.vendor.name}
                            src={tool.vendor.logo_url || ''}
                            size="sm"
                          />
                          {tool.vendor.name}
                        </Row>
                      </TableCell>

                      <TableCell className="font-medium">
                        <Row className="items-center gap-2">
                          <Badge className="bg-red-100 text-red-800">
                            {tool.calculated?.monthlyUserVisits} visits
                          </Badge>
                        </Row>
                      </TableCell>

                      <TableCell className="font-medium">
                        ${tool.calculated?.priceMonth}
                      </TableCell>

                      <TableCell className="font-medium">
                        ${tool.calculated?.priceAnnual}
                      </TableCell>
                    </TableRow>
                  )
                })}

              <TableRow className="bg-white">
                <TableCell className="font-normal text-muted-foreground">
                  Total
                </TableCell>

                <TableCell className="font-medium"></TableCell>

                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    ${tools_?.toolsStats?.totalMonthlyWasteLowUsage?.toFixed(2)}
                  </Badge>
                </TableCell>

                <TableCell className="font-medium">
                  <Badge className="bg-red-100 text-red-800">
                    ${tools_?.toolsStats?.totalAnnualWasteLowUsage?.toFixed(2)}
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ScrollArea>
      </Card>
    )
  }

  const OverlappingTools = () => {
    if (!overlappingTools?.data?.length) {
      return (
        <Card>
          <Row className="items-center gap-2">
            <Blend className="w-5 h-5" />
            <p className="text-lg font-semibold">Overlapping tools</p>

            <Tooltip content="Generate insights" delayDuration={0}>
              <Button
                variant="outline"
                size="icon"
                className="ml-auto"
                disabled={tools_?.toolsStats?.totalActiveUsers === 0}
                isLoading={generateOverlappingTools.isLoading}
                onClick={async () => {
                  await generateOverlappingTools
                    .mutateAsync({
                      organization_id: orgId,
                    })
                    .then(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.overlapping_tools],
                      })
                    })
                }}
              >
                <RefreshCcw className="h-4 w-4" />
              </Button>
            </Tooltip>
          </Row>

          <Alert variant="default" className="mt-4">
            <Row className="gap-2 items-center">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Press the button to the right to generate insights.
              </AlertDescription>
            </Row>
          </Alert>
        </Card>
      )
    }

    return (
      <Card>
        <Row className="items-center gap-2">
          <Blend className="w-5 h-5" />
          <p className="text-lg font-semibold">Overlapping tools</p>

          <Tooltip content="Generate insights" delayDuration={0}>
            <Button
              variant="outline"
              size="sm"
              className="ml-auto"
              isLoading={generateOverlappingTools.isLoading}
              onClick={async () => {
                await generateOverlappingTools
                  .mutateAsync({
                    organization_id: orgId,
                  })
                  .then(() => {
                    queryClient.invalidateQueries({
                      queryKey: [queryKeys.overlapping_tools],
                    })
                  })
              }}
            >
              <RefreshCcw className="h-4 w-4" />
            </Button>
          </Tooltip>
        </Row>

        <ScrollArea className="h-[240px]">
          <div className="grid grid-cols-3 gap-4 mt-4">
            {overlappingTools?.data?.map((tool) => (
              <OverlappingTool key={tool.id} tool={tool} />
            ))}
          </div>
        </ScrollArea>
      </Card>
    )
  }

  return (
    <Column className="gap-4 w-full">
      <Column className="gap-4">
        <SeatUtilization />
        <LowUsage />
        <LowUserCount />
        <OverlappingTools />
      </Column>
    </Column>
  )
}
