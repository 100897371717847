import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getColorFromNameByUsingHash } from 'src/shared/utils'

import { Tool_ } from '..'

dayjs.extend(relativeTime)

/**
 * @param tools Data from the tools table
 * @returns Stats for the tools
 */
export const getToolsStats = (tools: Tool_[]) => {
  const annualSpend = tools.reduce((acc, tool) => {
    return acc + tool.calculated.priceAnnual
  }, 0)

  const monthlySpend = tools.reduce((acc, tool) => {
    return acc + tool.calculated.priceMonth
  }, 0)

  const totalActiveUsers = new Set(
    tools
      .filter((tool) => tool.is_tracking)
      .filter((tool) => !tool.is_desktop_tool)
      .flatMap((tool) => tool.calculated.orgUserIds || [])
  ).size

  const totalActiveSubscriptions = tools.reduce((acc, tool) => {
    return acc + (tool.active_subscriptions?.[0] ? 1 : 0)
  }, 0)

  const totalMonthlyWasteSeatUtilization = tools
    .filter((tool) => !tool.is_desktop_tool)
    .filter((tool) => {
      const userCount = tool.calculated?.userCount
      const numberOfSeats = tool.active_subscriptions?.[0]?.number_of_seats || 0
      const utilization = (userCount / numberOfSeats) * 100

      return (
        tool.status === 'in_stack' &&
        tool.active_subscriptions?.[0]?.pricing_model === 'PER_SEAT' &&
        utilization < 100
      )
    })
    .reduce((acc, tool) => {
      const userCount = tool.calculated?.userCount
      const pricePerSeat = tool.active_subscriptions?.[0]?.price_per_seat || 0
      const numberOfSeats = tool.active_subscriptions?.[0]?.number_of_seats || 0
      const monthlyWaste = (numberOfSeats - userCount) * pricePerSeat
      return acc + monthlyWaste
    }, 0)

  const totalAnnualWasteSeatUtilization = totalMonthlyWasteSeatUtilization * 12

  const totalMonthlyWasteLowUsage = tools
    .filter((tool) => !tool.is_desktop_tool)
    .filter((tool) => {
      const active_users = tool.calculated?.userCount
      const utilization = (active_users / totalActiveUsers) * 100
      return (
        tool.status === 'in_stack' &&
        utilization < 50 &&
        tool.calculated?.priceMonth > 0
      )
    })
    .reduce((acc, tool) => acc + (tool.calculated?.priceMonth || 0), 0)

  const totalAnnualWasteLowUsage = totalMonthlyWasteLowUsage * 12

  const departments = [
    'Product',
    'IT',
    'Sales',
    'Marketing',
    'HR',
    'Data & Analytics',
    'Engineering',
    'Finance',
    'Customer Success',
    'Legal',
    'Other',
  ]

  const spendByCategory = departments.reduce((acc, department) => {
    acc[department] = {
      tool: department,
      cost: 0,
      fill: getColorFromNameByUsingHash(department),
    }
    return acc
  }, {})

  tools.forEach((tool) => {
    const department = tool.department || 'Other'
    if (spendByCategory[department]) {
      spendByCategory[department].cost += tool.calculated.priceAnnual
    }
  })

  const chartData = Object.values(spendByCategory)

  return {
    annualSpend,
    monthlySpend,
    totalActiveUsers,
    totalActiveSubscriptions,
    totalMonthlyWasteSeatUtilization,
    totalAnnualWasteSeatUtilization,
    totalMonthlyWasteLowUsage,
    totalAnnualWasteLowUsage,
    chartData,
  }
}
