export function getColorFromNameByUsingHash(name: string) {
  // Hash the name to get a numeric value
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Convert the hash to a hexadecimal color
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff // Get the last 8 bits
    color += ('00' + value.toString(16)).slice(-2) // Convert to hex and pad
  }

  return color
}
