import { useMutation } from '@tanstack/react-query'
import { useToast } from 'src/shared/hooks/use-toast'

import { ServerRoutes } from './serverRoutes'

interface InviteAdminsProps {
  emails: string
  organization_id: string
}

export const useInviteAdmins = () => {
  const toast = useToast()

  return useMutation({
    mutationFn: async (props: InviteAdminsProps) => {
      const response = await fetch(
        process.env.REACT_APP_BE_SERVER_URL + ServerRoutes.inviteAdmins,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            emails: props.emails,
            organization_id: props.organization_id,
          }),
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.msg || 'Failed to invite admins')
      }

      return response
    },
    onSuccess: () => {
      toast.toast({
        title: 'Admins invited successfully',
        variant: 'success',
        duration: 5000,
      })
    },
    onError: (error: Error) => {
      toast.toast({
        title: 'Something went wrong',
        description: error.message,
        variant: 'destructive',
        duration: 5000,
      })
    },
  })
}
