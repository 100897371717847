import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useSupabaseClient } from 'src/api/supabase'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

import { OrgUser_, User_ } from '../apiOrgUsers'
import { Subscription_ } from '../apiSubscriptions'
import { Vendor_ } from '../apiVendors'
import { queryKeys } from '../queryKeys'
import { calculateActiveSubscription } from './utils/calculateActiveSubscription'
import { getToolsStats } from './utils/getToolsStats'

dayjs.extend(relativeTime)

export type InsertTool = Database['public']['Tables']['tool']['Insert']
export type UpdateTool = Database['public']['Tables']['tool']['Update']
export type Tool_ = Database['public']['Tables']['tool']['Row'] & {
  budget_owner: OrgUser_ & {
    user: User_
  }
  vendor: Vendor_
  active_subscriptions: Subscription_[]
  expired_subscriptions: Subscription_[]

  // Calculated stuff for the active subscription
  calculated: {
    pricingModel: any
    renewsIn: number
    nextRenewalDate: string
    startsAt: string | null
    cancelledAt: string | null

    priceMonth: number
    priceQuarter: number
    priceAnnual: number

    priceMonthFormatted: string
    priceQuarterFormatted: string
    priceAnnualFormatted: string

    orgUserIds: number[]
    userCount: number
    monthlyUserCount: {
      label: string
      value: number
    }[]
    monthlyUserVisits: number
  }
}

export const useToolsApi = ({
  organization_id,
  org_user_id,
}: {
  organization_id?: string
  org_user_id?: number
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data: tools } = useQuery({
    queryKey: [queryKeys.tools],
    queryFn: async () => {
      const tools_ = await supabase
        .from('tool')
        .select(
          `*,
          budget_owner:org_user(*, user!org_user_user_id_fkey(*)),
          user_activity(*),
          vendor(*),
          active_subscriptions:subscription(*),
          expired_subscriptions:subscription(*)`
        )
        .eq('organization_id', organization_id!)
        .eq('active_subscriptions.status', 'ACTIVE')
        .eq('expired_subscriptions.status', 'EXPIRED')

      // '3358b7fd-4b6a-49ac-a3e0-f10585d37adb'

      const enrichedTools = tools_.data
        ?.filter((tool) => tool.vendor?.status !== 'blocked')
        .map((tool) => {
          const calculated = calculateActiveSubscription(
            tool.active_subscriptions as Subscription_[],
            tool.user_activity
          )

          const enrichedTool: any = {
            ...tool,
            calculated,
          }

          return enrichedTool as Tool_
        })

      const toolsStats = getToolsStats(enrichedTools as any)

      return {
        tools: enrichedTools,
        toolsStats,
      }
    },
    enabled: !!organization_id,
  })

  const { data: toolsAll } = useQuery({
    queryKey: [queryKeys.toolsAll],
    queryFn: async () => {
      const tools_ = await supabase
        .from('tool')
        .select(
          `*,
          budget_owner:org_user(*, user!org_user_user_id_fkey(*)),
          user_activity(*),
          vendor(*),
          active_subscriptions:subscription(*),
          expired_subscriptions:subscription(*)`
        )
        .eq('active_subscriptions.status', 'ACTIVE')
        .eq('expired_subscriptions.status', 'EXPIRED')

      const enrichedTools = tools_.data?.map((tool) => {
        const calculated = calculateActiveSubscription(
          tool.active_subscriptions as Subscription_[],
          tool.user_activity
        )

        const enrichedTool: any = {
          ...tool,
          calculated,
        }

        return enrichedTool as Tool_
      })

      const toolsStats = getToolsStats(enrichedTools as any)

      return {
        tools: enrichedTools,
        toolsStats,
      }
    },
  })

  const updateTool = useMutation({
    mutationFn: async (updateData: UpdateTool) =>
      await supabase.from('tool').update(updateData).eq('id', updateData.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Updated tool',
      })
    },
  })

  const addTool = useMutation({
    mutationFn: async (tool: InsertTool) =>
      await supabase.from('tool').insert(tool),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
    },
  })

  const upsertTools = useMutation({
    mutationFn: async (tools: InsertTool[]) =>
      await supabase.from('tool').upsert(tools, {
        onConflict: 'vendor_id',
        ignoreDuplicates: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })

      toast({
        variant: 'success',
        title: 'Tools added. Some tools might be generating in the background.',
      })
    },
  })

  const deleteTool = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      await supabase.from('tool').delete().eq('id', id).select('vendor_id')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.tools] })
      queryClient.invalidateQueries({ queryKey: [queryKeys.toolsAll] })
    },
  })

  const userTools = useQuery({
    queryKey: [queryKeys.userTools, org_user_id],
    queryFn: async () => {
      const tools_ = await supabase
        .from('user_activity')
        .select('id, tool(*, vendor(*), subscription(*))')
        .eq('org_user_id', org_user_id!)
        .eq('tool.status', 'not_in_stack')

      return tools_.data
    },
    enabled: !!org_user_id,
  })

  return {
    tools,
    toolsAll,
    updateTool,
    deleteTool,
    addTool,
    upsertTools,
    userTools,
  }
}
