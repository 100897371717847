import React from 'react'

import { cn } from './utils'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string
}

const Image: React.FC<ImageProps> = ({ className, alt, ...props }) => {
  return (
    <img
      className={cn('max-w-full h-auto', className)}
      alt={alt || 'Image'}
      {...props}
    />
  )
}

export { Image }
