import { useQuery } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { useAuth } from 'src/shared/hooks/authProvider'

import { queryKeys } from './queryKeys'

export const useOrgIdApi = () => {
  const supabase = useSupabaseClient()
  const { session } = useAuth()

  const { data: orgId_ } = useQuery({
    queryKey: [queryKeys.org_id],
    queryFn: async () =>
      await supabase
        .from('user')
        .select('current_org_id')
        .filter('id', 'eq', session?.user?.id)
        .single(),
    enabled: !!session?.user?.id,
  })

  return {
    orgId: orgId_?.data?.current_org_id || '',
  }
}
